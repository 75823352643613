import React, {useContext} from 'react'
import {CauseContext} from '../../contexts/CauseContext'
import history from "../../utils/history";
import Dropdown from 'react-bootstrap/Dropdown';


const CauseDetailView = ({id}) => {
  const context = useContext(CauseContext)
  const { publishCampaign,  unpublishCampaign, selectedCause, enableABTestingForCampaign,  disableABTestingForCampaign, productCount, staticOrDynamic, setStaticOrDynamic, updateTargeting, subscriptionTier} = context
  const {
    name,
    last_published_at,
    updated_at,
    page_type,
    products,
    content,
    stylesheet,
    attributes,
    targeting,
  } = selectedCause; 

  const handleEditProducts = () => {
    history.push(`/edit_cause/${id}/product`)
  }

  const handleEditDesign = () => {
    history.push(`/edit_cause/${id}/designer`)
  }

  const handleReports = () => {
    history.push(`/reports`)
  }

  const getBanner = () => {
    if (stylesheet) {
      return stylesheet + content;
    }
    return content;
  }

  const getIsEditorDisabled = () => {
    if (page_type === 'CART') {
      return true;
    }
    if (attributes == null || attributes.size == null) {
      return true;
    }

    return false;
  }

  const showStaticOrDynamic = () => {
    if(subscriptionTier === "BASIC"){
      setStaticOrDynamic("static")
      return("static")
    }
    if(targeting["product_ids"].length > 0){
      setStaticOrDynamic("static")
      return("static")
    }
    if(targeting["display"].length > 0){
      setStaticOrDynamic("dynamic")
      return("dynamic")
    }
    setStaticOrDynamic("static")
    return("static")
  }

  const handleSwitchTargeting = () => {
    if(staticOrDynamic === "static"){
      setStaticOrDynamic("dynamic")
    }
    if(staticOrDynamic === "dynamic"){
      setStaticOrDynamic("static")
    }
    history.push(`/edit_cause/${id}/product`)
  }

  const getProductTags = () => {
    var tempList = []
    //We are currently only supporting either AND or OR logic with tags so targeting["display"] will always be one list [{},{}] or [ [{}], [{}]]
    if(targeting["display"].length === 1){ //AND case where all conditions are within one nested list
      var conditions = targeting["display"][0]
      for(var i = 0; i < conditions.length; i++){
        tempList.push(conditions[i]["value"][0])
      }
      return tempList
    }
    else if(targeting["display"].length > 1){ //Or case with nested list of lists 
      for(var i = 0; i < targeting["display"].length; i++){
        tempList.push(targeting["display"][i][0]["value"][0])
      }
      return tempList
    }
    else {
      return []
    }
  }
  
  return (
    <div className="row design-detail">
    <div className="col-12">
      <div className="row title-row">
      <div className="col-6">
        <h4 style={{fontSize:'24px', fontWeight:'bold'}}>{name}</h4>
      </div>
      <div className="col-6 publish-button">
        {last_published_at === null && 
          <button type="button" className="btn btn-info"  style={{backgroundColor:'#20267A', borderColor:'#20267A', fontWeight:'bold'}}onClick={() => publishCampaign(id)}>Publish Campaign</button>
        }
        {last_published_at && 
           <button type="button" className="btn btn-secondary" style={{backgroundColor:'#FFFFFF', borderColor:'#000000', color:'#000000', fontWeight:'bold'}}  onClick={() => unpublishCampaign(id)}>Unpublish Campaign</button>
        }
      </div>
      
      </div>
      <div className="row">
        <div className="col-4" style={{margin:"auto"}}>
        <div className="card" style={{display:"table", margin:"auto"}}>
          <div className="card-body">
            {/*<h5 className="card-title" >{name}</h5>*/}
            <div dangerouslySetInnerHTML={{__html: getBanner()}}/>
            <p style={{fontSize:'14px'}}><b>Location:</b> {page_type === 'PDP' ? 'Product Detail Page' : 'Cart'}</p>
            <p style={{fontSize:'14px'}}><b>Published:</b> {last_published_at ? (new Date(last_published_at)).toString() : "--"}</p>
            <p style={{fontSize:'14px'}}><b>Last Updated:</b> {(new Date(updated_at)).toString() || "--"}</p>
            
          </div>
        </div>
        </div>
        <div className="col-8 detail-content">
          <div className="row">
          {getIsEditorDisabled() ?
              <div className="col">
                <h5 style={{fontSize:'18px', fontWeight:'bold'}}>Content</h5>
                <p>A site visitor will see this campaign when they go to a specific product's page. <i>(Editor coming soon)</i></p>
              </div>
            :
              <div className="col">
                <h5 style={{fontSize:'18px', fontWeight:'bold'}}>Content</h5>
                <p>A site visitor will see this campaign when they go to a specific product's page.</p>
              </div>
            }
            {getIsEditorDisabled() ?
              <div className="col link-button">
                <button type="button" className="btn btn-light"   disabled={true} >Edit Design</button>
              </div>
            :
              <div className="col link-button">
                <button type="button" className="btn btn-light" style={{backgroundColor:'#FFFFFF', borderColor:'#000000', color:'#000000', fontWeight:'bold'}} onClick={handleEditDesign} disabled={false} >Edit Design</button>
              </div>
            }

          </div>
          <div className="row">
          <div className="col">
              <h5 style={{fontSize:'18px', fontWeight:'bold'}}>Product Targeting</h5>
              {showStaticOrDynamic() === "static" ?
                (targeting["product_ids"].length === 1 ?
                  <p>This campaign will display on <b>{targeting["product_ids"][0] === -1 ?  " " + productCount + " products": "1 product"}</b></p>
                  :
                  <p>This campaign will display on <b>{targeting["product_ids"].length} products</b></p>
                )
                :
                  <p>This campaign will display on <b>products tagged "{getProductTags().join(", ")}"</b></p>
              }

          </div>
            <div className="col link-button">
              <Dropdown>
                <Dropdown.Toggle className="targetingDropdown">
                  Edit Targeting
                </Dropdown.Toggle>
                  {showStaticOrDynamic() === "static" ?
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={handleEditProducts}> Edit Products</Dropdown.Item>
                        {subscriptionTier === "PRO" &&
                          <Dropdown.Item as="button" onClick={handleSwitchTargeting}> Switch to dynamic targeting</Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    :
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={handleEditProducts}> Edit Condition</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={handleSwitchTargeting}> Switch to static targeting</Dropdown.Item>
                      </Dropdown.Menu>

                  }

              </Dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h5 style={{fontSize:'18px', fontWeight:'bold'}}>Reporting</h5>
              <p>Wondering how your campaign is performing? Visit your reports dashboard!</p>
            </div>
            <div className="col link-button">
              <button type="button" className="btn btn-light" style={{backgroundColor:'#FFFFFF', borderColor:'#000000', color:'#000000', fontWeight:'bold'}} onClick={handleReports} disabled={false} >Visit Reports</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default CauseDetailView;