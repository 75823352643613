import React from "react";


const EditorPublishSave = ({handleSaveBanner, status, bannerIsSaving, handlePublishCampaign, handleUnpublishCampaign}) => {
    return (
        <div className="editorPublishSave">
        {status === 0 ?
            <div className="editorPublishSave">
                <p className="text">This campaign is unpublished</p>
                <button type="button" onClick={handlePublishCampaign} className="btn btn-light" style={{color:'white', margin:'0px 10px 0px 10px', paddingBottom:'5px', backgroundColor:'transparent'}}>Publish Campaign</button>
            </div>
        :
            <div className="editorPublishSave">
                <p className="text">This campaign is published, any changes you save will automatically be live.</p>
                <button type="button" onClick={handleUnpublishCampaign} className="btn btn-light" style={{color:'white',margin:'0px 10px 0px 10px', paddingBottom:'5px', backgroundColor:'transparent'}}>Unpublish Campaign</button>
            </div>
        }
        {bannerIsSaving ?
            <button className="btn btn-light" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Saving...
            </button>
        :
            <button type="button" onClick={handleSaveBanner} className="btn btn-light"style={{margin:'0px 10px 0px 10px', paddingBottom:'5px'}}>Save</button>

        }
        </div>
    );
}

export default EditorPublishSave;