import React, {useState, useEffect} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth0 } from "../react-auth0-spa";
import logo from '../images/Because-Logo-White.png'
import bell from '../images/notification.png'


const TopNav = () => {
  const {logout, user, org} = useAuth0();
  const nickname = user ? user.given_name : "USERNAME";
  const showIntegration = true
  const path = window.location.pathname;
  console.log('path', path);
  const orgsToHideReportTabFrom = [79, 97]

  const [logoClicked, setLogoClicked] = useState(false);

  const handleLogoClick = () => {
    setLogoClicked(true);
  }

  useEffect(() => {
    window.Headway.init()
  }, []);

  const shouldShowReportsTab = () => {
    return !(orgsToHideReportTabFrom.includes(org));
  }

  return (
    <div >
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="topNav">
    <Navbar.Brand>
    <NavLink to="/" activeClassName="active" onClick={handleLogoClick}>
      <img src={logo} width="150px"  alt="because logo"></img>
    </NavLink>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    {user &&
    <Navbar.Collapse id="responsive-navbar-nav" style={{color:'white'}}>
       <Nav className="mr-auto">
         {(path == "/" || path == "/causes" || logoClicked) &&
         <div style={{display:'flex'}}>
           <div>
              <Nav.Link href="/causes" style={{color:'white'}}>Campaigns</Nav.Link>
              <div style={{backgroundColor:'white', height:'5px'}}></div>
           </div>
            <Nav.Link href="/resources" >Help Center</Nav.Link>
            {shouldShowReportsTab() && <Nav.Link href="/reports" >Reports</Nav.Link>}
         </div>
         }
         {path == "/resources"  &&
          <div style={{display:'flex'}}>
              <Nav.Link href="/causes" >Campaigns</Nav.Link>
              <div>
                <Nav.Link href="/resources" style={{color:'white'}}>Help Center</Nav.Link>
                <div style={{backgroundColor:'white', height:'5px'}}></div>
              </div>
              {shouldShowReportsTab() && <Nav.Link href="/reports" >Reports</Nav.Link>}
          </div>
         }
         {path == "/reports"  &&
          <div style={{display:'flex'}}>
              <Nav.Link href="/causes" >Campaigns</Nav.Link>
              <Nav.Link href="/resources" >Help Center</Nav.Link>
              {shouldShowReportsTab() &&
              <div>
              <Nav.Link href="/reports" style={{color:'white'}}>Reports</Nav.Link>
              <div style={{backgroundColor:'white', height:'5px'}}></div>
            </div>
              }
          </div>
         }

       </Nav>
       <Nav className="ml-auto">
        <div className="changelog" id="changelog" >
          <img src={bell} id="changelogBell" onClick={() => window.Headway.show()} style={{maxWidth:'30px', cursor:'pointer'}}></img>
        </div>
       <NavDropdown title={nickname} id="basic-nav-dropdown" style={{fontSize: "14px", fontWeight:"500", color:"#FFFFFF"}}>
           <LinkContainer to="/profile">
             <NavDropdown.Item  eventKey="3">Profile</NavDropdown.Item>
           </LinkContainer>
           <NavDropdown.Divider />
           <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
       </NavDropdown>
       </Nav>
    </Navbar.Collapse>
    }      
    </Navbar>
    </div>
 
  );
};

export default TopNav;