import React, {useContext, useEffect} from 'react'
import {CauseContext} from '../contexts/CauseContext'

import CauseDetailView from "../components/Cause/CauseDetailView"
import Loading from "../components/Loading"



const CauseDetail = (props) => {
  const id = props.match.params.id
  const context = useContext(CauseContext)
  const { selectedCause, getCampaign } = context
    
  useEffect(() => {
    getCampaign(id)
    
  }, [])

  return (
    <>
      {selectedCause.id &&  <CauseDetailView id={id}/>}
      {!selectedCause.id &&  <Loading/>}

    </>
  );
};

export default CauseDetail;