import React, {useContext, useEffect, useState} from 'react'
import {CauseContext} from '../contexts/CauseContext';
import { Link } from 'react-router-dom';
import { GoInfo } from "react-icons/go";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import emptyReportFlower from '../images/report_empty_state_image.png';



const Reports = () => {
    const context = useContext(CauseContext);
    const {getCampaignPerformance, campaignPerformance, reportLoading, orgIsSubscribed} = context;

    const renderBannerViewsTopline = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            A banner view happens every time one of your product pages is loaded and a Because banner is displayed. This is the cumulative number of views since you installed the Because app to your store.
        </Tooltip>
    );

    const renderRevenueTopline = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Revenue counts as influenced by Because when a banner is displayed on a product that is subsequently purchased. This is the cumulative revenue influenced since you installed the Because app to your store.
        </Tooltip>
    );

    useEffect(() => {
        getCampaignPerformance();
    }, []);

    const getIntlFormat = (num) => {
        var currencyToLocale = {
            'AUD': 'en-AU',
            'EUR': 'de-DE',
            'CAD': 'en-CA',
            'USD': 'en-US',
            'BRL': 'pt-BR',
            'GBP': 'en-GB'
          };
        var currency_for_rev = campaignPerformance.currency || "USD";
        var locale = currencyToLocale[currency_for_rev]
        var result = new Intl.NumberFormat(locale,{ style: 'currency', currency: currency_for_rev, currencyDisplay: 'narrowSymbol' }).format(Math.round(num*10)/10);
        return result
    }

    const formatToplineRevenue = () => {
        const original_rev_influenced = campaignPerformance.topline_revenue; 
        if (original_rev_influenced >= 1000000) {
            const result = getIntlFormat(original_rev_influenced/1000000);
            if (campaignPerformance.currency == 'EUR') {
                const resultAsString = result.toString();
                const euroSymbol = resultAsString.slice(-1)
                const stringWithoutEuro = resultAsString.substring(0, resultAsString.length-2);
                const finalString = stringWithoutEuro + 'M' + euroSymbol;
                return finalString;
            }
            return result + 'M';
        }
        if (original_rev_influenced >= 1000) {
            const result = getIntlFormat(original_rev_influenced/1000);
            if (campaignPerformance.currency == 'EUR') {
                const resultAsString = result.toString();
                const euroSymbol = resultAsString.slice(-1)
                const stringWithoutEuro = resultAsString.substring(0, resultAsString.length-2);
                const finalString = stringWithoutEuro + 'K' + euroSymbol;
                return finalString;
            }
            return result + 'K';
        }
        return getIntlFormat(original_rev_influenced);

    }

    const formatDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

        const year = date.getFullYear()
        const month = monthNames[date.getMonth()]
        const day = date.getDate()
        console.log(month)
        return month +" " + day + ", " + year;
    }

    return (
        <div>
            {reportLoading ?
            <div>
            <div className="loading">
              <h1 >Fetching your report...</h1>
              <Spinner animation="border" size="lg" variant="secondary" />
            </div>
            </div>
            :
            <div>
            <div className="reportingTitleBar">
                <h2 className="reportingPageTitle">Campaign Performance Dashboard</h2>
                {orgIsSubscribed && campaignPerformance.campaign_performance.length >= 1 &&
                    <div className="reportingPageButtonContainer">
                    <Link to='/new_cause'>
                        <button type="button" style={{backgroundColor:'#FF0097', borderColor:'#FF0097', fontWeight:'bold', marginTop:'50px'}} className="btn btn-info">Create Campaign</button>
                    </Link>
                    </div>
                }
            </div>
            <div className="toplineMetricsContainer">
                <div className="bannerViewsTopline">
                    <div style={{display:"flex"}}>
                        <p className="toplineViewsTitles"> Total banner views</p>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderBannerViewsTopline}>
                            <GoInfo style={{margin:'5px'}}/>
                        </OverlayTrigger>
                    </div>
                    <p className="toplineNumbers">{campaignPerformance.topline_banner_views}</p>
                </div>
                <div className="revenueInfluencedTopline">
                    <div style={{display:"flex"}}>
                        <p className="toplineRevenueTitles">Total revenue influenced</p>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderRevenueTopline}>
                            <GoInfo style={{marginLeft:'5px', marginTop:'5px', marginBottom:'5px'}}/>
                        </OverlayTrigger>
                    </div>
                    <p className="toplineNumbers">{formatToplineRevenue()}</p>
                </div>
            </div>
            <div className="reportingPageLine"></div>
            <div style ={{width:"80%", margin:"auto"}}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Campaign name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Last published</th>
                            <th scope="col">Views</th>
                            <th scope="col">Conversions</th>
                            <th scope="col">Conversion rate</th>
                            <th scope="col">Revenue influenced</th>
                        </tr>
                    </thead>
                        <tbody>
                            {campaignPerformance.campaign_performance.map((item, index) => {
                                return (                                   
                                        <tr key={index}>
                                            <td className="align-middle">{item.campaign_name}</td>
                                            <td className="align-middle">{item.campaign_status}</td>
                                            <td className="align-middle">{item.last_published ? formatDate(new Date(item.last_published)): "N/A"}</td>
                                            <td className="align-middle">{item.views}</td>
                                            <td className="align-middle">{item.conversions}</td>
                                            <td className="align-middle">{item.conversion_rate}</td>
                                            <td className="align-middle">{getIntlFormat(item.revenue_influenced)}</td>
                                        </tr>
                                )
                            })}
                        </tbody>      
                    
                </table>
                {campaignPerformance.campaign_performance.length < 1 &&
                    <div className ="noCampaignContainer">
                            <div>
                                <img className="reportEmptyImg" src={emptyReportFlower} /> 
                                <h1 className="reportEmptyHeader">See your business grow</h1>
                                <p className="reportEmptyText">
                                    Publish at least one campaign and start seeing how it's performing
                                    across banner views, purchases, conversion rate, and influenced revenue.
                                    Questions? Chat with us below.
                                </p>
                                {orgIsSubscribed &&                      
                                    <Link to='/new_cause'>
                                        <button type="button" style={{backgroundColor:'#FF0097', borderColor:'#FF0097', fontWeight:'bold', margin:'auto'}} className="btn btn-info">Create Campaign</button>
                                    </Link>
                                }
                            </div>
                    </div>
                }
            </div>
            </div>
            }

        </div>
    )
};

export default Reports