import React from "react";

const ProductTargetingFooter = ({saveChanges, cancelChanges, savingProducts}) => {

    return (
        <div className="footer">
        {savingProducts ?
          <button style={{textAlign:'left', height:'auto', marginLeft:'25px', marginRight:'10px', marginTop:'10px', marginBottom:'10px'}} className="btn btn-primary" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Saving...
          </button>
        :
        <button style={{textAlign:'left', height:'auto', marginLeft:'25px', marginRight:'10px', marginTop:'10px', marginBottom:'10px'}} className="btn btn-primary" onClick={saveChanges}>Save</button>
        }
        <button style={{textAlign:'right', height:'auto', margin:'10px'}}  className="btn btn-light" onClick={cancelChanges}>Cancel</button>
      </div>
    )
};

export default ProductTargetingFooter;