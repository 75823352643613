import React from 'react'
import UserProfile from '../components/UserProfile'

const Profile = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <UserProfile/>
            </div>
        </div>
    )
};

export default Profile