import React, { useState } from 'react'
import mockPic from '../../images/dash_image.png'
import { Link } from 'react-router-dom'
import stepOne from '../../images/Onboarding_Step_1.png'
import stepTwo from '../../images/Onboarding_Step_2.png'
import stepThree from '../../images/Onboarding_Step_3.png'

const CauseNone = () => {
  const [showOverview, setShowOverview] = useState(false)
  if(showOverview === false){
    return (
      <div className="row justify-content-center align-items-center cause-step">
        <div className="col-md-4 text-side">
            <p className="empty-state-header">Share the 1 reason your customer should buy, above "add to cart"</p>
            <p className="empty-state-text">Your most important messaging, in a place where your customer can't miss it. What message will you share first?</p>
            <button type="button" style={{fontSize:"1.2vw"}} onClick={() => setShowOverview(true)} className="btn btn-primary">Get Started</button>
        </div>
        <div className="col-md-4 d-none d-md-block img-side">
            <img src={mockPic} className="cause-pic" alt="cause pic"/>
        </div>
      </div>
    );
  } else {
    return (
      <div className="campaign-creator">
          <h3 className="campaign-input-label-step-2" style={{paddingBottom:'10px'}}>It's super easy to publish your first Because Banner</h3>
          <div style={{height:'100%', display:'flex'}}>
              <div id="large" className="button-size-card-container">
                  <div id="large" className="button-size-card">
                      <div id="large" className="button-card-contents">
                          <img  id="large" src={stepOne} className="button-size-image-no-campaign" width={'95%'} height={'auto'}/>
                          <h3 id="large" className="button-size-card-title">1. Select The Products to Update</h3>
                          <p id="large" className="button-size-card-text" style={{fontWeight:'normal'}}>Select the products that should have your banner using Shopify tags or checkboxes.</p>
                      </div>
                  </div>
               </div>
               <div id="medium" className="button-size-card-container">
                  <div id="medium"  className="button-size-card">
                      <div id="medium"  className="button-card-contents">
                          <img id="medium"  src={stepTwo} className="button-size-image-no-campaign" width={'95%'} height={'auto'}/>
                          <h3 id="medium"  className="button-size-card-title">2. Create Your Message {"&"} Design</h3>
                          <p id="medium"  className="button-size-card-text" style={{fontWeight:'normal'}}>Edit your colors, icons, font, and more all without code. Publish live in minutes!</p>
                      </div>
                  </div>
               </div>
               <div id="small" className="button-size-card-container">
                  <div id="small" className="button-size-card">
                      <div id="small" className="button-card-contents">
                          <img id="small" src={stepThree} className="button-size-image-no-campaign" width={'95%'} height={'auto'}/>
                          <h3 id="small" className="button-size-card-title">3. Publish On Your Store!</h3>
                          <p id="small" className="button-size-card-text" style={{fontWeight:'normal'}}>That's all! Just hit Publish and your product pages will be instantly updated.</p>
                      </div>
                  </div>
                  <Link to='/new_cause'>
                    <button type="button" style={{fontSize:"1.2vw", marginTop:'15%', fontWeight:'bold'}} className="btn btn-primary">Create First Campaign</button>
                  </Link>   
               </div>
              
          </div>
          
      </div>
    )
  }
};

export default CauseNone;