import React, {useContext, useEffect, useState, useRef} from 'react'
import {CauseContext} from '../../contexts/CauseContext'
import staticImage from '../../images/static_selection_icon.png'
import dynamicImage from '../../images/dynamic_tags_icon.png'
import {Tooltip} from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap'
import { GoInfo } from "react-icons/go";

const NewCampaignStep3 = ({currentStep, setStaticOrDynamic, staticOrDynamic}) => {

  const context = useContext(CauseContext)
  const { productList, setProductList, selectedCause, authToken, setSelectedCause, productCount, fetchingProducts, getProductList, setFetchingProducts, savingProducts, subscriptionTier} = context
  const {
    id,
    name,
    products,
    shop_name,
  } = selectedCause;

  if (currentStep !== 3) {
        return null;
  }

  const handleStaticClick = () => {
    setStaticOrDynamic("static")
  }

  const handleDynamicClick = () => {
    setStaticOrDynamic("dynamic")
  }

  const renderStaticTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This option is like a snapshot or static list.
      If you want to update a static list of products, you can do it manually.
    </Tooltip>
  );

  const renderDynamicTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This option is a list that updates over time adding new products
      that match your display conditions and removing products that no longer match your conditions.
    </Tooltip>
  );

  return (
    <div>

        <div className="staticOrDynamicContainer">

          <div className="staticOrDynamicHeader">
            <h1 style={{fontSize:'24px', fontWeight:'bold'}}>How do you want to choose your products? </h1>
            <p>Don't worry, you can always change this later.</p>
          </div>

          <button
            className={staticOrDynamic === "static" ? "staticOrDynamicButtonClicked": "staticOrDynamicButton"}
            onClick={handleStaticClick}
          >
            <img className="staticOrDynamicImage" src={staticImage} />
            <div>
              <div className="staticOrDynamicInfo">
                <h2  style={{fontSize:'20px', fontWeight:'bold'}}>Choose products 1 by 1</h2>
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderStaticTooltip}
                >
                <GoInfo/>
                </OverlayTrigger>
              </div>
              <p>Recommended for stores with less than 50 products or single product campaigns. Your campaign will not update; it will remain static.</p>
            </div>
          </button>

          <button
            className={staticOrDynamic === "dynamic" ? "staticOrDynamicButtonClicked": "staticOrDynamicButton"}
            onClick={handleDynamicClick}
            disabled={subscriptionTier === "BASIC"}
          >
            <img className="staticOrDynamicImage" src={dynamicImage} />
            <div>

              <div className="staticOrDynamicInfo">
                <h2 style={{fontSize:'20px', fontWeight:'bold'}}>Target products using tag(s)</h2>
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderDynamicTooltip}
                >
                <GoInfo style={{size:'2px'}}/>
                </OverlayTrigger>
              </div>
              {subscriptionTier === "BASIC" ?
                  <p>I want to display it on products using tag(s) - available in Pro Subscription</p>
                :
                  <p>Recommended for stores with hundreds or thousands of products. Your campaign will automatically update as your product tags update in Shopify.</p>
              }
            </div>
          </button>

        </div>

    </div>

  );
};

export default NewCampaignStep3;