import React, { useState} from "react";
import Alert from 'react-bootstrap/Alert';
import { GoInfo } from "react-icons/go";
import { useAuth0 } from "../../react-auth0-spa";



const EditorDesignRenderer = ({htmlToRender, createProperty, seenAlertProp}) => {
    const {org} = useAuth0();
    const [show, setShow] = useState(!seenAlertProp);
    const handleDismiss= () =>{
        createProperty("dismissed_banner_publish_alert", "USER", "True");
        setShow(false);
    }    
    const shouldShowAlert = () => {
        return org > 221;
      }    

    return (
        <div className="editorDesignRenderer">
            {show && shouldShowAlert() &&
                <Alert className= "installerAlertContainer" variant="info"  onClose={() => handleDismiss()} dismissible>
                    <div className="alertInfoIcon">
                        <GoInfo> </GoInfo>
                    </div>
                    <p>
                    Every shop theme is different so the Because banner may not appear on your product page automatically or it might not look how you want it to.
                    Don't worry! We can easily fix this for you. If you're having any problems chat with us below or email app-support@becauseintelligence.com
                    </p>
                </Alert>
            }
            <div className="bannerRenderer">
                <div dangerouslySetInnerHTML={{__html: htmlToRender}}/>
            </div>
        </div>
    );
}

export default EditorDesignRenderer;