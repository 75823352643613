import React, { useEffect}  from 'react'
import { useAuth0 } from "../react-auth0-spa";
import history from "../utils/history";



const Connected = () => {
    const { org , getTokenSilently} = useAuth0()

    function getCookie(n) {
        let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
        return a ? a[1] : '';
    }
    function handleButton(e){
        window.close()
    }

    const handleLoggedIn= async (code, hmac, shop, timestamp) => {
        const token = await getTokenSilently();

        const payload = JSON.stringify({
            code: code,
            hmac: hmac,
            shop: shop,
            timestamp: timestamp
        })
        fetch('https://api.because-intelligence.com/connect_shopify', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .then(data => {
            window.opener.location.reload();
        })
       
    }

    const handleNotLoggedIn = async (code, hmac, shop, timestamp, host) => {
        const payload = JSON.stringify({
            code: code,
            hmac: hmac,
            shop: shop,
            timestamp: timestamp,
            host:host
        })
        fetch('https://api.because-intelligence.com/connect_shopify_from_listing', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .then(data => {
            history.push(`/sign_up/${paramsToQueryString(data)}`)
        })
       
    }

    function paramsToQueryString(params) {
        let queryString = '?&website_=' + params['website'] + '&token_id=' + params['token_id']
        return queryString
    }

    useEffect(() => {
        const fn = async () => {
            const code = new URLSearchParams(window.location.search).get('code');
            const hmac = new URLSearchParams(window.location.search).get('hmac');
            const shop = new URLSearchParams(window.location.search).get('shop');
            const timestamp = new URLSearchParams(window.location.search).get('timestamp');
            const host = new URLSearchParams(window.location.search).get('host');

            if (org) {
                handleLoggedIn(code, hmac, shop, timestamp)
            } else {
                handleNotLoggedIn(code, hmac, shop, timestamp, host)
            }
        }
        fn()
    }, [])

    return (
        <>
        {org &&
            <div className="row">
                <div className="col-12 main">
                    <h1 className="welcome">Your store was successfully connected</h1>
                    <h3 className="listen-label">You can now seamlessly incorporate cause marketing on your site!</h3>
                    <button className="btn btn-primary" onClick={handleButton}>Close Window</button>
                </div>
            </div>
        }
        </>
    )
    
}

export default Connected