import React, {useState, useContext} from 'react'
import EditorTopNav from '../Editor/EditorTopNav'
import DesignContainer from '../Editor/DesignContainer'
import {CauseContext} from '../../contexts/CauseContext'
import { useAuth0 } from "../../react-auth0-spa";
import { Prompt } from 'react-router'

const CauseDesign = ({selectedCause}) => {
  const { getTokenSilently } = useAuth0();
  const {
    id,
    stylesheet,
    param_map,
    attributes,
    content
  } = selectedCause; 

  const [params, setParams] = useState(param_map);
  const [activeFontFamily, setActiveFontFamily] = useState(params['font_family'])
  const [stylesheetForBanner, setStylesheetForBanner] = useState(stylesheet || '');
  const [savingBanner, setSavingBanner] = useState(false)
  const context = useContext(CauseContext)
  const { publishCampaign, unpublishCampaign, setSelectedCause, updateCampaignName, createProperty, seenAlertProp } = context

  const handlePublishCampaign = async () => {
    doSaveBanner();
    publishCampaign(id);
  }

  const handleUnpublishCampaign = async () => {
    unpublishCampaign(id);
  }

  const handleUpdateCampaignName = async (updatedName) => {
    updateCampaignName(id, updatedName);
  }

  const handleChangeIcon = (icon_url) => {
    setParams({...params, 'icon_url': icon_url});
  }

  const handleChange = (event) => {
      let value = event.target.value;
      if ((event.target.name == 'body_text' || event.target.name == 'header_text') && !value) {
        value = " ";
      }
      setParams({...params, [event.target.name]: value});
  }

  const handleChangeNumericInput = (name, event) => {
      const value = event;
      setParams({...params, [name]: value});
  }

  const handleChangeFontPicker = (nextFont) => {
      const font = nextFont.family;
      if (font) {
        const updatedStylesheet = `<link href="https://fonts.googleapis.com/css?family=${font}" rel="stylesheet">`;
        setParams({...params, 'font_family': font});
        setStylesheetForBanner(updatedStylesheet);
        setActiveFontFamily(font);
      }
  }

  const handleIconUpload = async (data) => {
      const response = await fetch('https://api.because-intelligence.com/upload_icon', {
          method: 'POST',
          body: data
      });
      return response.json();
  }

  const handleChangeFileUpload = async (event) => {
      const fileToUpload = event.target.files[0]
      if (fileToUpload) {
      //do some validation maybe
      const data = new FormData();
      data.append('file', fileToUpload);
      const iconUrl = await handleIconUpload(data);
      setParams({...params, 'icon_url': iconUrl});
      }
  }
  const typeOfBanner = () =>{
    if(attributes.size == 'medium'){
      return 'medium'
    }
    if(attributes.size == 'small'){
      return 'small'
    }
    return 'large'
  }
  const getHtmlToSave = () => {
    const type = typeOfBanner()
    const mediumButtonTemplatePopulated =`<div style="background-color:${params.background_color_hex_code} !important;display:flex !important;border-radius:5px !important;width:${params.banner_width};max-width:100%;align-items:center;color:${params.font_color_hex_code};font-family:${params.font_family};line-height:${params.line_height};margin:${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px !important"><img src="${params.icon_url}" width=${params.icon_width}px;height=auto; style="margin: ${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"><div><p style="font-size:${params.header_font_size}px;font-weight:${params.header_font_weight};margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};text-align:${params.text_align} !important; padding:0 !important;line-height:${params.line_height} !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;font-weight:${params.body_font_weight};margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};text-align:${params.text_align} !important;padding:0 !important;line-height:${params.line_height} !important">${params.body_text}</p></div></div>`
    const smallButtonTemplatePopulated = `<div style="background-color:${params.background_color_hex_code}!important;display:flex !important;width:${params.banner_width};max-width:100%;border-radius:5px !important;align-items:center;color:${params.font_color_hex_code};font-family:${params.font_family};line-height: ${params.line_height};margin: ${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px"><div><p style="font-size:${params.header_font_size}px;margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-weight:${params.header_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important;text-align:${params.text_align} !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-weight:${params.body_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important;text-align:${params.text_align} !important">${params.body_text}</p></div></div></div>`
    const longButtonTemplatePopulated = `<div style="display:inline-block !important;margin: ${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px !important;width: ${params.banner_width};"><div style="background-color:${params.background_color_hex_code}!important;display:flex !important;border-radius:5px !important;width:100% !important;align-items:center !important;text-align:center;vertical-align:middle !important;color:${params.font_color_hex_code}!important;font-family:${params.font_family} !important;margin:auto;"><div style="text-align:center;margin: auto;display:flex !important;align-items:center !important;"><img src="${params.icon_url}" width="${params.icon_width}px;" height="auto;" style="margin: ${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"><div><p style="font-size:${params.header_font_size}px;margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-weight:${params.header_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;font-weight:${params.body_font_weight};margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important">${params.body_text}</p></div><img src="${params.icon_url}" width=${params.icon_width}px; height=auto; style="margin:${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"></div></div></div>`
    if(type === 'small'){
      return smallButtonTemplatePopulated;
    }
    if(type === 'medium'){
      return mediumButtonTemplatePopulated;
    }
      return longButtonTemplatePopulated;
  }

  const updateCampaignBanner = async (token, id, html, stylesheet) => {
    const payload =  JSON.stringify({
        campaign_id: id,
        banner_html: html,
        stylesheet: stylesheet
    })

    return fetch(`https://api.because-intelligence.com/update_banner`, {
        method: 'PATCH',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: payload
    })
    .then(response => {
        setSavingBanner(false);
        return response.json();
    })
    .catch( e => {
      setSavingBanner(false);
       console.log(e);
    })
    .then(updatedCampaign => {
      setSelectedCause(updatedCampaign);
        return updatedCampaign;
    })
}

  const doSaveBanner = async () => {
    const token = await getTokenSilently();
    const html = getHtmlToSave();
    const updatedCampaign = await updateCampaignBanner(token, id, html, stylesheetForBanner);
  }


  const handleSaveBanner = async () => {
      setSavingBanner(true);
      doSaveBanner();
  }
  const shouldBlockNavigation = () => {
    const htmlToSave = getHtmlToSave();
    return content !== htmlToSave;
  }

  return (
    <div>
      <Prompt
      when={shouldBlockNavigation()}
      message='Are you sure you want to leave this page? Changes you made may not be saved'
      />
      <EditorTopNav
      selectedCause={selectedCause}
      handleSaveBanner={handleSaveBanner.bind(this)}
      bannerIsSaving={savingBanner}
      handlePublishCampaign={handlePublishCampaign.bind(this)}
      handleUnpublishCampaign={handleUnpublishCampaign.bind(this)}
      handleUpdateCampaignName={handleUpdateCampaignName.bind(this)}
    />
    <DesignContainer 
      selectedCause={selectedCause}
      params={params}
      stylesheetForBanner={stylesheetForBanner}
      activeFontFamily={activeFontFamily}
      handleChange={handleChange.bind(this)}
      handleChangeNumericInput={handleChangeNumericInput.bind(this)}
      handleChangeFontPicker={handleChangeFontPicker.bind(this)}
      handleChangeFileUpload={handleChangeFileUpload.bind(this)}
      handleChangeIcon={handleChangeIcon.bind(this)}
      typeOfBanner={typeOfBanner.bind(this)}
      createProperty={createProperty.bind(this)}
      seenAlertProp={seenAlertProp}
   />
    </div>
  );
};

export default CauseDesign;