import React, { useState, useContext, useEffect } from "react";
import { ResponsiveEmbed } from "react-bootstrap";
import { useAuth0 } from "../react-auth0-spa";

export const CauseContext = React.createContext();
export const useCause = () => useContext(CauseContext)
export const CauseProvider = ({
    children,
}) => {

    //state 
    const [selectedCause, setSelectedCause] = useState({});
    const [productList, setProductList] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [fetchingProducts, setFetchingProducts] = useState(false);
    const [savingProducts, setSavingProducts] = useState(false);
    const [campaignPerformance, setCampaignPerformance] = useState({});
    const [reportLoading, setReportLoading] = useState(true)
    const [authToken, setAuthToken] = useState();
    const [campaignList, setCampaignList] = useState();
    const [creatingCampaign, setCreatingCampaign] = useState(false);
    const [orgIsSubscribed, setOrgIsSubscribed] = useState();
    const [seenAlertProp, setSeenAlertProp] = useState();
    const [staticOrDynamic, setStaticOrDynamic] = useState("");
    const [subscriptionTier, setSubscriptionTier] = useState()

    //effects
    useEffect(() => {
        getTokenSilently().then(async token => {
            setAuthToken(token);
        })
        .catch(e => {console.log(e)});
        getOrgIsSubscribed().catch(e => {console.log(e)}); 
        if(authToken != undefined){
            getProperties('dismissed_banner_publish_alert','USER').catch(e => {console.log(e)}); 
            getProperties('subscription_tier', 'ORG').catch(e => {console.log(e)}); 
            getProductCount();
        }
    }, [authToken]);


    // methods
    const { getTokenSilently } = useAuth0();

    const getCampaignList = async () => {
        const token = await getTokenSilently();
        fetch(`https://api.because-intelligence.com/campaigns`, {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
            console.log(e)
        })
        .then(data => {
            setCampaignList(data)
        })

    }

    const getOrgIsSubscribed = async () => {
        const token = await getTokenSilently();
        fetch(`https://api.because-intelligence.com/org_is_subscribed`, {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then(data => {
            setOrgIsSubscribed(data['subscribed']);
        })

    }

    const confirmSubscriptionForOrg = async (id) => {
        const token = await getTokenSilently();
        const body = JSON.stringify({subscription_id: id});
        fetch(`https://api.because-intelligence.com/confirm_subscription`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: body,
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then(data => {
            if (data == "OK") {
                setOrgIsSubscribed(true);
            }
        })

    }

    const handleCampaignCreation = async (campaign) => {
        setCreatingCampaign(true)
        const body = JSON.stringify(campaign);
        const token = await getTokenSilently();
        return fetch('https://api.because-intelligence.com/create_campaign', {
          method: 'POST',
          body: body,
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        }).then(response => {
            setCreatingCampaign(false);
            return response.json();
        })
        .catch( e => {
            setCreatingCampaign(false);
            console.log(e);
         })
        .then(createdCampaign => {
            setSelectedCause(createdCampaign);
            return createdCampaign;
        })
      
    } 
   
    const getCampaign = async (id) => {
        const token = await getTokenSilently();
        fetch(`https://api.because-intelligence.com/campaigns`, {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then(data => {
            setSelectedCause(data[data.findIndex(x => x.id === parseInt(id))] || {})
        })
    }

    const getCampaignPerformance = async () => {
        setReportLoading(true);
        const token = await getTokenSilently();
        fetch(`https://api.because-intelligence.com/campaign_performance`, {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e);
           setReportLoading(false);
        })
        .then(data => {
            setCampaignPerformance(data)
            setReportLoading(false);
        })
    }

    const publishCampaign = async (id) => {
        const payload =  JSON.stringify({campaign_id: id})
        fetch(`https://api.because-intelligence.com/publish_campaign`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: payload
        })
        .then(response => {
            getCampaignList()
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then( updatedCampaign => {
            setSelectedCause(updatedCampaign);
        })
    }

    const unpublishCampaign = async (id) => {
        const payload =  JSON.stringify({campaign_id: id})
         fetch(`https://api.because-intelligence.com/unpublish_campaign`, {
            method: 'POST',
             headers:{
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${authToken}`
             },
             body: payload
         })
         .then(response => {
             getCampaignList()
             return response.json();
         })
         .catch( e => {
            console.log(e)
         })
         .then( updatedCampaign => {
            setSelectedCause(updatedCampaign);
        })
    }

    const deleteCampaign = async (id) => {
        const payload =  JSON.stringify({campaign_id: id})
         fetch(`https://api.because-intelligence.com/delete_campaign`, {
            method: 'POST',
             headers:{
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${authToken}`
             },
             body: payload
         })
         .then(response => {
             getCampaignList()
             return response.json();
         })
         .catch( e => {
            console.log(e)
         })
    }

    const cloneCampaign = async (id, newCampaignName, copyProducts) => {
        const payload =  JSON.stringify({original_campaign_id: id, new_campaign_name: newCampaignName, copy_products: copyProducts })
        fetch(`https://api.because-intelligence.com/clone_campaign`, {
            method: 'POST',
             headers:{
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${authToken}`
             },
             body: payload
         })
         .then(response => {
             getCampaignList()
             return response.json();
         })
         .catch( e => {
            console.log(e)
         })
    }

    const updateCampaignName = async (id, updatedName) => {
        const payload =  JSON.stringify({campaign_id: id, updated_name: updatedName})
        fetch(`https://api.because-intelligence.com/update_campaign_name`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: payload
        })
        .then(response => {
            getCampaignList()
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then( updatedCampaign => {
            setSelectedCause(updatedCampaign);
        })
    }

    const createProperty = async (propertyName, propertyType, propertyValue) => {
        const payload =  JSON.stringify({property_name: propertyName, property_type: propertyType, property_value: propertyValue})
        fetch(`https://api.because-intelligence.com/create_properties_row`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then( data => {
            setSeenAlertProp(true);
        })
    }

    const getProperties = async (propertyName, propertyType) => {
        const payload =  JSON.stringify({property_name: propertyName, property_type: propertyType})
        fetch(`https://api.because-intelligence.com/get_properties_row`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
           console.log(e)
        })
        .then( data => {
            if(propertyName === "dismissed_banner_publish_alert"){
                setSeenAlertProp(data['property_value'] === 'True' ? true : false);
            }
            if(propertyName === "subscription_tier"){
                setSubscriptionTier(data['property_value']);
            }
        })
    }

    const getProductCount = async () => {
        fetch(`https://api.because-intelligence.com/get_product_count`, {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch( e => {
            console.log(e)
        })
        .then(data => {
          setProductCount(data)
        })
    }

    const getProductList = async (page_str, cursor_str, search_param) => {
        setFetchingProducts(true)
        const token = await getTokenSilently();
        const payload =  JSON.stringify({page: page_str, cursor: cursor_str, search_param: search_param})
        fetch(`https://api.because-intelligence.com/fetch_product_pageql`, {
            method: 'POST',
            body: payload,
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            setFetchingProducts(false)
            return response.json();
        })
        .catch( e => {
            setFetchingProducts(false)
            console.log(e)
        })
        .then(data => {
            setProductList(data)
        })
    }

    const updateTargeting = async (values) => {
        setSavingProducts(true)
        let cause = selectedCause
        const payload = JSON.stringify(values)
        fetch(`https://api.because-intelligence.com/update_targeting`, {
            method:'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: payload
        })
        .then(response => {
            setSavingProducts(false)
            //history.push(`/cause/${values.campaign_id}`)
            return response.json();
        })
        .catch( e => {
        setSavingProducts(false)
        console.log(e)
        })
        .then(data => {
            setSelectedCause(cause)
        })
    }

    return (
        <CauseContext.Provider 
            value = {{
                authToken,
                selectedCause,
                productList,
                campaignList,
                orgIsSubscribed,
                seenAlertProp,
                campaignPerformance,
                reportLoading,
                productCount,
                fetchingProducts,
                savingProducts,
                staticOrDynamic,
                subscriptionTier,
                setAuthToken: (...p) => setAuthToken(...p),
                setCampaignPerformance: (...p) => setCampaignPerformance(...p),
                setSelectedCause: (...p) => setSelectedCause(...p),
                setStaticOrDynamic: (...p) => setStaticOrDynamic(...p),
                getCampaignList: (...p) => getCampaignList(...p),
                getCampaignPerformance: (...p) => getCampaignPerformance(...p),
                getOrgIsSubscribed: (...p) => getOrgIsSubscribed(...p),
                setProductList: (...p) => setProductList(...p),
                findSelectedCampaign:(...p) => getCampaign(...p),
                publishCampaign:(...p) => publishCampaign(...p),
                unpublishCampaign:(...p) => unpublishCampaign(...p),
                deleteCampaign:(...p) => deleteCampaign(...p),
                cloneCampaign:(...p) => cloneCampaign(...p),
                updateCampaignName:(...p) => updateCampaignName(...p),
                getCampaign:(...p) => getCampaign(...p),
                handleCampaignCreation:(...p) => handleCampaignCreation(...p),
                confirmSubscriptionForOrg:(...p) => confirmSubscriptionForOrg(...p),
                createProperty:(...p) => createProperty(...p),
                getProperties:(...p) => getProperties(...p),
                getProductList:(...p) => getProductList(...p),
                updateTargeting:(...p) => updateTargeting(...p),
            }}>
            {children}
        </CauseContext.Provider>
    )

}