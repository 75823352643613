import React, { useContext } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Loading from './Loading'
import {CauseContext} from '../contexts/CauseContext'

const UserProfile = () => {
  const { loading, user } = useAuth0();
  const context = useContext(CauseContext)
  const {subscriptionTier} = context

  if (loading || !user) {
    return <Loading/>;
  }
  return (
    <div>
      <br/>
      <h1>Profile</h1>
      <br/>
      <img src={user.picture} alt="Profile" />
      <br/>
      <br/>
      <p>Email address: {user.email}</p>
      <br/>
      <br/>
      <p>Subscription Tier: {subscriptionTier}</p>
      <p>If you would like to switch your plan please contact <a href ="mailto: support@becauseintelligence.com" >support@becauseintelligence.com</a></p>
    </div>
  );
};

export default UserProfile;