import React from "react";
import Navbar from "react-bootstrap/Navbar";
import EditorPublishSave from '../Editor/EditorPublishSave';
import { BiPencil } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import EdiText from 'react-editext'





const EditorTopNav = ({selectedCause, handleSaveBanner, bannerIsSaving, handlePublishCampaign, handleUnpublishCampaign, handleUpdateCampaignName}) => {
    const {
        name,
        last_published_at,
        updated_at,
        content,
        products,
        status
      } = selectedCause;     

  return (
    <div className="editorTopNav">
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="editorTopNav">
        <div className="container-fluid">
            <div className="nameEditingContainer">
              <h3 className="editorTitle"> 
                <EdiText 
                  editOnViewClick = {true}
                  inputProps={{
                    className: 'campaignNameEditBox'
                  }}
                  saveButtonContent = { <BiCheck size = {30} />}
                  saveButtonClassName = "saveCampaignNameButton"
                  cancelButtonContent = { <BsX size = {30} /> }
                  cancelButtonClassName = "cancelCampaignNameButton"
                  editButtonClassName = "editCampaignNameButton"
                  editButtonContent =  { <BiPencil size={30} /> }
                  type='text' 
                  value={name}
                  submitOnEnter
                  cancelOnEscape
                  cancelOnUnfocus
                  validationMessage = "Campaign name must be between 1 and 100 characters"
                  validation = {val => val.length > 0 & val.length <= 101} 
                  hideIcons = {true}
                  onSave={handleUpdateCampaignName}
                /> 
              </h3>
            </div>
            <EditorPublishSave
              handleSaveBanner={handleSaveBanner.bind(this)}
              status={status}
              bannerIsSaving={bannerIsSaving}
              handlePublishCampaign={handlePublishCampaign.bind(this)}
              handleUnpublishCampaign={handleUnpublishCampaign.bind(this)}
            />
        </div>
    </Navbar>
    </div>
 
  );
};

export default EditorTopNav;