import React from 'react';
import './App.css';
import { Router, Switch, Route } from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

import AuthRoute from './components/AuthRoute'
import TopNav from './components/TopNav'
import Footer from './components/Footer'

import CauseDashboard from './pages/CauseDashboard'
import NewCampaignFormContainer from "./pages/NewCampaignFormContainer"
import CauseEditor from "./pages/CauseEditor"

import CauseDetail from "./pages/CauseDetail"
import Reports from "./pages/Reports"
import Resources from "./pages/Resources"
import Profile from "./pages/Profile"
import Connected from "./pages/Connected"
import Settings from './pages/Settings'
import Error from './pages/Error'
import SignUp from './pages/SignUp'
import Authenticate from './pages/Authenticate'
import Logout from './pages/Logout'

import history from "./utils/history";

import {CauseProvider} from './contexts/CauseContext'
import { useAuth0 } from "./react-auth0-spa"


function App() {
  const { loading } = useAuth0();
  toast.configure()

  if(loading) {
      return null;
  } else {
      return (
        <>
          <Router history={history} forceRefresh={true}>
          <TopNav/>
          <ToastContainer position={'top-center'} transition={Slide}/>
          <CauseProvider>
            <Switch>
              <AuthRoute exact path="/" component={CauseDashboard} />
              <AuthRoute path="/cause/:id" component={CauseDetail} />
              <AuthRoute path="/causes" component={CauseDashboard} />
              <AuthRoute path="/reports" component={Reports} />
              <AuthRoute path="/resources" component={Resources} />
              <AuthRoute path="/new_cause" component={NewCampaignFormContainer} />
              <AuthRoute path="/edit_cause/:id/:editType" component={CauseEditor} />
              <AuthRoute path="/profile" component={Profile} />
              <AuthRoute exact path="/settings" component={Settings}/> 
              <Route path="/connect_shopify" component={Connected}/>
              <Route path="/sign_up" component={SignUp}/>
              <Route path="/authenticate" component={Authenticate}/>
              <Route path="/log_out" component={Logout}/>
              <Route component={Error}/>
            </Switch>
          </CauseProvider>
          </Router>
        </>
      )

  }
}

export default App;
