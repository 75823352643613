import React, { useState } from "react";
import EditorSideBar from './EditorSideBar'
import EditorDesignRenderer from './EditorDesignRenderer'

const DesignContainer = ({selectedCause, params, stylesheetForBanner, activeFontFamily, handleChange, handleChangeNumericInput, handleChangeFontPicker, handleChangeFileUpload, handleChangeIcon, typeOfBanner, createProperty, seenAlertProp}) => {
    const {
        name,
        last_published_at,
        updated_at,
        content,
        products,
        status,
        stylesheet,
        param_map,
        attributes
      } = selectedCause; 


    const getHtmlToRender = () => {
        let bannerHTML = ''
        const type = typeOfBanner()
        const mediumButtonTemplatePopulated =`<div style="background-color:${params.background_color_hex_code} !important;display:flex !important;border-radius:5px !important;width:${params.banner_width};max-width:100%;align-items:center;color:${params.font_color_hex_code};font-family:${params.font_family};line-height:${params.line_height};margin:${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px !important"><img src="${params.icon_url}" width=${params.icon_width}px;height=auto; style="margin: ${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"><div><p style="font-size:${params.header_font_size}px;font-weight:${params.header_font_weight};margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};text-align:${params.text_align} !important; padding:0 !important;line-height:${params.line_height} !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;font-weight:${params.body_font_weight};margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};text-align:${params.text_align} !important;padding:0 !important;line-height:${params.line_height} !important">${params.body_text}</p></div></div>`
        const smallButtonTemplatePopulated = `<div style="background-color:${params.background_color_hex_code}!important;display:flex !important;width:${params.banner_width};max-width:100%;border-radius:5px !important;align-items:center;color:${params.font_color_hex_code};font-family:${params.font_family};line-height: ${params.line_height};margin: ${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px"><div><p style="font-size:${params.header_font_size}px;margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-weight:${params.header_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important;text-align:${params.text_align} !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-weight:${params.body_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important;text-align:${params.text_align} !important">${params.body_text}</p></div></div></div>`
        const longButtonTemplatePopulated = `<div style="display:inline-block !important;margin: ${params.banner_margin_top}px 0px ${params.banner_margin_bottom}px 0px !important;width: ${params.banner_width};"><div style="background-color:${params.background_color_hex_code}!important;display:flex !important;border-radius:5px !important;width:100% !important;align-items:center !important;text-align:center;vertical-align:middle !important;color:${params.font_color_hex_code}!important;font-family:${params.font_family} !important;margin:auto;"><div style="text-align:center;margin: auto;display:flex !important;align-items:center !important;"><img src="${params.icon_url}" width="${params.icon_width}px;" height="auto;" style="margin: ${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"><div><p style="font-size:${params.header_font_size}px;margin: ${params.header_margin_top}px ${params.header_margin_right}px ${params.header_margin_bottom}px ${params.header_margin_left}px;font-weight:${params.header_font_weight};font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important">${params.header_text}</p><p style="font-size:${params.body_font_size}px;font-weight:${params.body_font_weight};margin: ${params.body_margin_top}px ${params.body_margin_right}px ${params.body_margin_bottom}px ${params.body_margin_left}px;font-family:${params.font_family};color:${params.font_color_hex_code};line-height:${params.line_height} !important;padding:0 !important">${params.body_text}</p></div><img src="${params.icon_url}" width=${params.icon_width}px; height=auto; style="margin:${params.img_margin_top}px ${params.img_margin_right}px ${params.img_margin_bottom}px ${params.img_margin_left}px;width:${params.icon_width}px;height:auto;"></div></div></div>`
        
        if(type === 'small'){
            bannerHTML= smallButtonTemplatePopulated;
          }
          else if(type === 'medium'){
            bannerHTML= mediumButtonTemplatePopulated;
          }else{
            bannerHTML= longButtonTemplatePopulated;
          }
            
        const fullHtml = stylesheetForBanner + bannerHTML;
        return fullHtml;
    }

    return (
        <div className="designContainer">
            <EditorSideBar
                params={params}
                handleChange={handleChange.bind(this)}
                handleChangeNumericInput={handleChangeNumericInput.bind(this)}
                handleChangeFontPicker={handleChangeFontPicker.bind(this)}
                handleChangeFileUpload={handleChangeFileUpload.bind(this)}
                handleChangeIcon={handleChangeIcon.bind(this)}
                activeFontFamily={activeFontFamily}
                typeOfBanner={typeOfBanner.bind(this)}
            />
            <EditorDesignRenderer 
              htmlToRender={getHtmlToRender()} 
              createProperty={createProperty.bind(this)}
              seenAlertProp={seenAlertProp}
            />
        </div>
        
    );
}

export default DesignContainer;