import React, { useState, useContext } from 'react'
import CauseName from '../components/Cause/CauseName'
import CauseDisplay from '../components/Cause/CauseDisplay'
import NewCampaignStep1 from '../components/Cause/NewCampaignStep1'
import NewCampaignStep2 from '../components/Cause/NewCampaignStep2'
import NewCampaignStep3 from "../components/Cause/NewCampaignStep3"
import NewCampaignStep4 from '../components/Cause/NewCampaignStep4'
import NewCampaignFooter from '../components/Cause/NewCampaignFooter'
import ProductTargeting from '../components/Cause/ProductTargeting'
import { useAuth0 } from "../react-auth0-spa";
import { withRouter } from 'react-router-dom';
import {CauseContext} from '../contexts/CauseContext'

const NewCampaignFormContainer = (props) => {
    const { getTokenSilently } = useAuth0();
    const [currentStep, setCurrentStep] = useState(1);
    const [staticOrDynamic, setStaticOrDynamic] = useState("")
    const [tagAnyOrAll, setTagAnyOrAll] = useState("ALL")
    const context = useContext(CauseContext)
    const { selectedCause, creatingCampaign, handleCampaignCreation, subscriptionTier} = context
    const [campaign, setCampaign] = useState({
        id: 0,
        name: "",
        page_type: "PDP",
        placement: "ADD_TO_CART",
        content: "default html",
        attributes: {
            size: "hi"
        },
    })

    const handleChange = (event) => {
        const value = event.target.value;
        setCampaign({...campaign, [event.target.id]: value});
    }

    const handleSizeSelection = (size) => {
        setCampaign({...campaign, attributes: {size: size}});
        console.log(campaign);
    }

    const handleGoToNextStep =  async () => {
        console.log('props', props)
        if (currentStep === 4) {
            const createdCampaign = await handleCampaignCreation(campaign);
            console.log("logging new campaign", createdCampaign)
            props.history.push({
                pathname: `/edit_cause/${createdCampaign.id}/designer`,
                state: {selectedCause: selectedCause}});
            return;
        }
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
    }

    const handleGoBack = () => {
        const prevStep = currentStep - 1;
        setCurrentStep(prevStep);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const handleOnChangeForProductPage = (selectedProducts) => {
        //setCampaign({...campaign, products: selectedProducts});
        setCampaign({...campaign, product_ids: selectedProducts});
    }
    
    const handleOnChangeForProductPageTag = (tagList) => {
        var conditions_list = []
        if(tagAnyOrAll === "ALL"){
            for(var i in tagList){
                var condition = {field: "tag", operator: "is", value:[tagList[i]]}
                conditions_list.push(condition)
            }
            setCampaign({...campaign, display: [conditions_list]})
        }
        if(tagAnyOrAll === "ANY"){
            console.log("In any for creation")
            for(var i in tagList){
                var condition = [{field: "tag", operator: "is", value:[tagList[i]]}]
                conditions_list.push(condition)
            }
            setCampaign({...campaign, display: conditions_list})
        }
        console.log("loggin on change for tag", campaign)
    }

    const getIsNextDisabled = () => {
        if (currentStep === 1) {
            if (campaign.name) {
                return false;
            }
            return true;
        }
        if (currentStep === 2) {
            const size = campaign.attributes.size;
            console.log('disabled value', size);
            if (size === 'small' || size === 'medium' || size === 'large') {
                return false;
            }
            return true;
        }
        if (currentStep === 3) {
            if (staticOrDynamic === ""){
                return true;
            }
        }
    }

    return (
        <div className="full-height">
            <form style={{height:'100%'}} onSubmit={handleSubmit}>
                <NewCampaignStep1 
                    currentStep={currentStep} 
                    handleChange={handleChange.bind(this)}
                    name={campaign.name}
                    handleGoToNextStep={handleGoToNextStep.bind(this)}
                />
                <NewCampaignStep2
                    currentStep={currentStep} 
                    handleSizeSelection={handleSizeSelection.bind(this)}
                    size={campaign.attributes.size}
                />

                <NewCampaignStep3
                    currentStep={currentStep}
                    staticOrDynamic={staticOrDynamic}
                    setStaticOrDynamic={setStaticOrDynamic.bind(this)}
                    subscriptionTier={subscriptionTier}
                />

                <NewCampaignStep4
                    currentStep={currentStep} 
                    campaignName={campaign.name}
                    handleOnChangeForProductPage={handleOnChangeForProductPage.bind(this)}
                    handleOnChangeForProductPageTag={handleOnChangeForProductPageTag.bind(this)}
                    creatingCampaign={creatingCampaign}
                    staticOrDynamic={staticOrDynamic}
                    tagAnyOrAll={tagAnyOrAll}
                    setTagAnyOrAll={setTagAnyOrAll.bind(this)}
                />

            </form>
            <NewCampaignFooter
                currentStep={currentStep}
                getIsNextDisabled={getIsNextDisabled.bind(this)}
                handleGoBack={handleGoBack.bind(this)}
                handleGoToNextStep={handleGoToNextStep.bind(this)}
            />
        </div>
    )
};

export default NewCampaignFormContainer