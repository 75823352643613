import React, {useContext, useEffect, useState} from 'react'
import {CauseContext} from '../../contexts/CauseContext'
import history from "../../utils/history";
import Spinner from 'react-bootstrap/Spinner'
import ProductTargetingFooter from './ProductTargetingFooter'
import Pagination from 'react-bootstrap/Pagination'
import {GrNext, GrPrevious} from 'react-icons/gr'
import { GoInfo } from "react-icons/go";
import { AiOutlineClose} from 'react-icons/ai'
import { FormGroup } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import {Form, Row, Col, Container, Tooltip, OverlayTrigger} from 'react-bootstrap';
import Button from 'react-bootstrap/Button'





const ProductTargeting = () => {
  const context = useContext(CauseContext)
  const { productList, setProductList, selectedCause, authToken, setSelectedCause, productCount, fetchingProducts, getProductList, setFetchingProducts, savingProducts, updateTargeting, staticOrDynamic, setStaticOrDynamic} = context
  const {
    id,
    name,
    products,
    shop_name,
    targeting
  } = selectedCause; 

  const [selectedIDs, setSelectedIDS] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [tagAnyOrAll, setTagAnyOrAll] = useState("ALL")
  const [searchParam, setSearchParam] = useState("")

  //Use to fill clear buttons
  const getProductTags = () => {
    var tempList = []
    //We are currently only supporting either AND or OR logic with tags so targeting["display"] will always be one list [{},{}] or [ [{}], [{}]]
    if(targeting["display"].length === 1){ //AND case where all conditions are within one nested list
      var conditions = targeting["display"][0]
      for(var i = 0; i < conditions.length; i++){
        tempList.push(conditions[i]["value"][0])
      }
      setTagAnyOrAll("ALL")
      return tempList
    }
    else if(targeting["display"].length > 1){ //Or case with nested list of lists 
      for(var i = 0; i < targeting["display"].length; i++){
        tempList.push(targeting["display"][i][0]["value"][0]) //Must use 0th index because we only allow one condition. Will loop through eventually
        //when multiple conditions are supported
      }
      setTagAnyOrAll("ANY")
      return tempList
    }
    else {
      return []
    }
  }

  const [tagList, setTagList] = useState(getProductTags)

  console.log("Logging tagAnyOrALL", tagAnyOrAll)
  const setChecked = (e) =>{
    let ids = [...selectedIDs]
    if(e.target.value){
      ids.push(e.target.id)
    }else{
      ids = ids.filter((n) => {return n !== e.target.id})
    }
    setSelectedIDS(ids)
  }
 
  useEffect(() => {
    getProductList("","","")
  }, [])

  useEffect(() => {
    setProductData(productList)
  }, [productList])

  useEffect(() => {
    setSelectedIDS(targeting["product_ids"])
  }, [targeting["product_ids"]]) 
  
  const saveChanges = () => {
    if(staticOrDynamic === "static"){
      const values = {campaign_id : id, products: selectedIDs, display: []}
      updateTargeting(values) 
    } 
    else {
      var conditions_list = []
      if(tagAnyOrAll === "ALL"){
        for(var i in tagList){
          var condition = {field: "tag", operator: "is", value:[tagList[i]]}
          conditions_list.push(condition)
        }
        var values = {campaign_id : id, products: [], display: [conditions_list]}
        updateTargeting(values)
      }
      if(tagAnyOrAll === "ANY"){
        for(var i in tagList){
          var condition = [{field: "tag", operator: "is", value:[tagList[i]]}]
          conditions_list.push(condition)
        }
        var values = {campaign_id : id, products: [], display: conditions_list}
        updateTargeting(values)
      }
    }
    setTimeout(() => {  history.push(`/cause/${id}`); }, 1000);
  }

  const getNumOnPage = () => {
    if (productData.length == 0) {
      return 1;
    } else {
      return productData[0]?.["edges"].length + ((pageCount - 1)*100);
    }
  }

  const cancelChanges = () => {
    getProductList("","","")
    history.push(`/cause/${id}`)
  }

  const getVendor = (vendor) => {
      if (vendor.length > 20) {
        return vendor.substring(0, 20) + '...';
      } else {
        return vendor;
      }
  }

  const onClickClear = () => {
    setSelectedIDS([]);
  }

  const onClickNext = () => {
    setIsFirstFetch(false);
    if(!productData[0]?.["pageInfo"]["hasNextPage"]){
      return null;
    }
    if(productData[0]?.["pageInfo"]["hasNextPage"] && !fetchingProducts){
      getProductList("next", productData?.[1]["next_page"], searchParam)
      setTimeout(() => {setPageCount(pageCount + 1)}, 250)
    }
  }

  const onClickPrevious = () => {
    setIsFirstFetch(false);
    if(!productData[0]?.["pageInfo"]["hasPreviousPage"]){
      return null;
    }
    if(productData[0]?.["pageInfo"]["hasPreviousPage"] && !fetchingProducts){
      getProductList("previous", productData?.[2]["previous_page"],searchParam)
      setTimeout(() => {setPageCount(pageCount - 1)}, 250)
    }
  }

  const handleSelectedProducts = (item) => {
    if(selectedIDs[0] === -1){
      return true
    }
    else {
      return selectedIDs.includes(Number(item["node"]["id"].slice(22)))
    }
  }

  const onClickSelectAll = () => {
    setSelectedIDS([-1])
  }

  const renderSelectAllTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This displays your banner on every product in your store and every product you add to your store after. 
    </Tooltip>
  );

  const renderTagTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You are allowed up to 20 tags per campaign. This is not case sensitive and requires exact spelling.
    </Tooltip>
  );

  const handleTagSubmit = (event) => {
    event.preventDefault()
    let tags = [...tagList]
    var input = document.getElementById("product_tag").value;
    tags.push(input)
    document.getElementById("product_tag").value = "";
    if(input != ""){
      setTagList(tags)
    }
  }

  const handleTagClear = (tag) => {
    let tags = [...tagList]
    tags = tags.filter((n) => {return n !== tag})
    setTagList(tags)
  }

  const hanldeMatchingSwitch = (event) => {
    event.preventDefault()
    if(tagAnyOrAll === "ALL"){
      setTagAnyOrAll("ANY")
    }
    if(tagAnyOrAll === "ANY"){
      setTagAnyOrAll("ALL")
    }
  }

  const handleFilterProducts = (event) => {
    console.log("Button pressed")
    event.preventDefault()
    var input = document.getElementById("searchTitle").value;
    if(input != ""){
      setSearchParam(input)
      getProductList("", "", input)
    }
  }

  const handleClearFilter = (event) => {
    console.log("Button pressed")
    event.preventDefault()
    document.getElementById("searchTitle").value = "";
    setPageCount(1)
    setSearchParam("")
    getProductList("", "", "")
  }
  
  return (
    <div>
    {staticOrDynamic === "dynamic" &&
      <div className="dynamicHeader">
        <h1 style={{fontSize:'24px', fontWeight:'bold'}}>Where do you want to display this banner?</h1>
        <p>We'll add your {name} banner to products that match the following condition.</p>
        <Container  className="productConditionsInput" >
          <Form onSubmit={handleTagSubmit}>
            <Form.Row>
              <Col>
                <Form.Group as={Form.Row}>
                  <Col >  
                    <Form.Label column>Product tags</Form.Label>
                  </Col> 
                  <Col>
                    <Form.Control as="select" defaultValue={tagAnyOrAll} onChange={hanldeMatchingSwitch} custom>
                      <option value="ALL">Match ALL of</option>
                      <option  value="ANY">Match ANY of</option>
                    </Form.Control>
                  </Col> 
                </Form.Group >
              </Col>
              <Col>
                <Form.Group as={Row}>
                  <Col>
                    <Form.Control type="text" id="product_tag" disabled={tagList.length >= 20}/>
                    {tagList.length > 0 &&
                      <div className="tagButtons">
                        {tagList.map((item, key) => {
                          return(
                            <button key={key} className="tagClear" type="reset" onClick={(e) => handleTagClear(item)}>
                              {item}
                              <AiOutlineClose/>
                            </button>
                          )
                        })}
                      </div>
                    }
                  </Col>
                  <Button onClick={handleTagSubmit} className="submitTag">
                    Submit tag
                  </Button> 
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTagTooltip}
                    >
                    <GoInfo style={{size:'5px'}}/>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Container>
      </div>
      }
      {staticOrDynamic === "static" &&
        <div className="row product-target">
        <div className="col-12">
          <div  className="row">
          <div className="col-12 title">
            <h4 style={{fontWeight:'bold'}}>Which product pages should have this banner?</h4>
            <p>Once you publish in the next step, your {name} banner will show on the products you select.</p>
          </div>
          </div>
          {productData && selectedIDs &&
          <>
          <div className="row" style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
            <div style={{display:'flex', flexDirection:'row', textAlign:'center'}} class="col-sm">
              <p>{selectedIDs[0] === -1 ? productCount : selectedIDs.length} products selected |</p>
              <a onClick={onClickSelectAll} style={{cursor:'pointer', color:"#472eb4", marginLeft:'5px', marginRight:'5px'}}> Select all</a>
              <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderSelectAllTooltip}
              >
              <GoInfo style={{size:'3px'}}/>
              </OverlayTrigger>
              <p>|</p>
              <a onClick={onClickClear} style={{cursor:'pointer', color:"#472eb4", marginLeft:'5px', marginRight:'5px'}}> Clear</a>
            </div>
            <div class="col-sm" style={{alignSelf:'flexEnd'}}>
              <Form onSubmit={handleFilterProducts}>
                <Form.Row>
                  <Col>
                    <FormControl type="text" id="searchTitle" placeholder="Product Title" maxLength="50">
                    </FormControl>
                  </Col>
                  <Col xs="auto">
                    <Button type="submit" onClick={handleFilterProducts} className="submitTag">
                      Filter Products
                    </Button> 
                  </Col>
                  <Col xs="auto"> 
                    <Button onClick={handleClearFilter} className="submitTag">
                      Clear Filters
                    </Button> 
                  </Col>
                </Form.Row>
              </Form>
            </div>
          </div>
          <div style={{paddingBottom:'50px'}} className="row">
          <div className="col-12">
          <div className="productSelectorTable">
          <table style={{tableLayout:'fixed'}} className="table table-hover">
            <thead>
              <tr>
                <th style={{width:"60px", minWidth:"20%"}} scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Product</th>
                <th scope="col">Product Type</th>
                <th scope="col">Vendor</th>
              </tr>
            </thead>
            {fetchingProducts ?
                <thead>
                <tr>
                  <td colSpan="5">
                  <div className="productLoading">
                    <div className="loading">
                      {isFirstFetch &&
                        <h1 >Fetching your products...</h1>
                      }
                      <Spinner animation="border" size="lg" variant="secondary" />
                    </div>
                  </div>
                  </td>
                </tr>
                </thead>
                :
                <tbody>
                  {productData[0]?.["edges"].map((item, index) => {
                        return (
                          <tr key={index}>
                          <th scope="row">
                            <input className="form-control form-control-lg" type="checkbox" disabled={selectedIDs === [-1]} checked={handleSelectedProducts(item)} onChange={(e) => {
                              setChecked({
                                target: {
                                  id: Number(item["node"]["id"].slice(22)),
                                  value: e.target.checked,
                                },
                              });
                            }}></input>
                          </th>
                          {(() => {
                            if (item["node"]["images"]["edges"].length === 0){ 
                              return <td className="align-middle"><img src={item["node"]["images"]["edges"]} style={{width:"55px", height:"auto"}} /></td>
                            } else{ 
                              return <td className="align-middle"><img src={item["node"]["images"]["edges"][0]["node"]["originalSrc"]} style={{width:"55px", height:"auto"}} /></td>  
                            }
                          })()}
                          <td className="align-middle" style={{fontSize:"0.85rem", width:"40%"}}>{item["node"]["title"]}</td>
                          <td className="align-middle" style={{fontSize:"0.85rem"}}>{item["node"]["productType"]}</td>
                          <td className="align-middle" style={{fontSize:"0.85rem"}}>{getVendor(item["node"]["vendor"])}</td>
                        </tr>
                        )
                      })
                    }
                </tbody> 
          }
          </table>
          </div>
            <Pagination className="productSelectorPagination">
              <div className="productSelectorButton">
                { productData[0]?.["pageInfo"]["hasPreviousPage"] &&
                  <button onClick={onClickPrevious} disabled={fetchingProducts && productList} className="productPageButton"><GrPrevious></GrPrevious></button>
                }
                {searchParam === "" &&
                  <p className="paginationNumbers"> {1 + ((pageCount - 1)*100)} - {getNumOnPage()} of {productCount}</p>
                }
                { productData[0]?.["pageInfo"]["hasNextPage"] &&
                  <button onClick={onClickNext} disabled={fetchingProducts && productList} className="productPageButton"> <GrNext></GrNext></button>
                }
              </div>
            </Pagination>
          </div>
          </div>
          </>
          }
          {!productList && 
            <div className="row">
              <div className="col-12 title">
                <h6>Uh oh! There was a problem fetching your products. Please chat with us so we can troubleshoot.</h6>
              </div>
            </div>
          }
        </div>
        </div>
      }
        <div className="row">
          <div className="col-12 link-button">
          <ProductTargetingFooter saveChanges={saveChanges.bind(this)} cancelChanges={cancelChanges.bind(this)} savingProducts={savingProducts} />
          </div>
        </div>
    </div>
  );
};

export default ProductTargeting;