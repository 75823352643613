import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import noIcon from '../../images/No_icon_option.png'
import oneIcon from '../../images/1_icon_option.png'
import twoIcon from '../../images/2_icon_option.png'



const NewCampaignStep2 = ({currentStep, size, handleSizeSelection}) => {
    const [selected, setSelected] = useState(size || 'hi');
    if (currentStep !== 2) {
        return null;
    }

    const handleOnClick = (event) => {
        const size = event.target.id;
        if (size === 'small') {
            setSelected('small');
            handleSizeSelection('small');
        }
        if (size === 'medium') {
            setSelected('medium');
            handleSizeSelection('medium');
        }
        if (size === 'large') {
            setSelected('large');
            handleSizeSelection('large');
        }
    }

    return (
        <div className="campaign-creator">
            <h3 className="campaign-input-label-step-2">How many icons do you want on your banner?</h3>
            <p style={{fontWeight:'normal'}}>Most people choose two, but choose whatever you think will look best on your site!</p>
            <div style={{height:'100%', display:'flex'}}>
                <div id="large" onClick={handleOnClick} className="button-size-card-container">
                    <div id="large" className={selected === 'large' ? "button-size-card-selected" : "button-size-card"}>
                        <div id="large" className="button-card-contents">
                            <img  id="large" src={twoIcon} className="button-size-image" width={'95%'} height={'auto'}/>
                            <h3 id="large" className="button-size-card-title">2 icons</h3>
                            <p id="large" className="button-size-card-text">The more icons, the better! This style is best for sites with longer "add to cart" buttons.</p>
                        </div>
                    </div>
                 </div>
                 <div id="medium" onClick={handleOnClick} className="button-size-card-container">
                    <div id="medium"  className={selected === 'medium' ? "button-size-card-selected" : "button-size-card"}>
                        <div id="medium"  className="button-card-contents">
                            <img id="medium"  src={oneIcon} className="button-size-image" width={'95%'} height={'auto'}/>
                            <h3 id="medium"  className="button-size-card-title">1 icon</h3>
                            <p id="medium"  className="button-size-card-text">One simple icon. This style is best for sites with medium "add to cart" buttons.</p>
                        </div>
                    </div>
                 </div>
                 <div id="small" onClick={handleOnClick} className="button-size-card-container">
                    <div id="small" className={selected === 'small' ? "button-size-card-selected" : "button-size-card"}>
                        <div id="small" className="button-card-contents">
                            <img id="small" src={noIcon} className="button-size-image" width={'95%'} height={'auto'}/>
                            <h3 id="small" className="button-size-card-title">No icons</h3>
                            <p id="small" className="button-size-card-text">Minimalist without icons. This style is best for sites with small "add to cart" buttons.</p>
                        </div>
                    </div>
                 </div>
            
            </div>

      </div>
    )
}

export default NewCampaignStep2;