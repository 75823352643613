import React, {useState} from "react";
import mockPic from '../../images/dash_image.png'
import { useAuth0 } from "../../react-auth0-spa";

const StartFreeTrial = () => {
    const { getTokenSilently, org } = useAuth0();
    const [loading, setLoading] = useState(false)

    const handleClick = async () => {
        setLoading(true);
        const token = await getTokenSilently();
        fetch(`https://api.because-intelligence.com/start_free_trial`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            return response.json();
        })
        .catch((error) => {
            setLoading(false)
            console.error('Error:', error);
        })
        .then(data => {
            setLoading(false);
            window.location.assign(data);
        })
    }

    const getButtonText = () => {
        if (org && (org == 141)) {
            return "Initiate subscription"
        }
        return "Start free trial"
    }

  return (
    <div className="row justify-content-center align-items-center cause-step">
      <div className="col-md-4 text-side">
          <p className="empty-state-header">Share the 1 reason your customer should buy, above "add to cart"</p>
          <p className="empty-state-text">Your most important messaging, in a place where your customer can't miss it. What message will you share first?</p>
            {loading ?
            <button type="button" className="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Redirecting...
            </button>

            :
            <button type="button" className="btn btn-primary" style={{fontSize:"1.2vw"}} onClick={handleClick}>{getButtonText()}</button>
            }
      </div>
      <div className="col-md-4 d-none d-md-block img-side">
          <img src={mockPic} className="cause-pic" alt="cause pic"/>
      </div>
    </div>
  );
};

export default StartFreeTrial;