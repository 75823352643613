import React, { useState } from 'react'



const NewCampaignStep1 = ({currentStep, handleChange, handleGoToNextStep, name}) => {
    if (currentStep !== 1) {
        return null;
    }

    const checkIfEmpty = () =>{
        return name.length == 0;
    }

    const handleKeyPress = (event) => {
        if (event.key == 'Enter'){
            if(!checkIfEmpty()){
                handleGoToNextStep();
            }
            else{
                event.preventDefault()
            }
        }
    };

    return (
        <div className="campaign-creator">
            <h3 className="campaign-input-label">Let's make a banner! Give it an internal name?</h3>
            <p style={{marginBottom:'5%', fontWeight:'normal'}}>Don't worry - this is just for your team and can be changed later</p>
            <div className="campaign-creator-input">
            <label style={{textAlign:'left', width:'100%', fontWeight:'bold'}} htmlFor="email">Banner Name</label>
                <input
                    onKeyPress={handleKeyPress}
                    style={{width:'100%'}} 
                    id="name"
                    type="text"
                    value={name ? name : ""}
                    placeholder="20% OFF Promotion on Shirts"
                    onChange={handleChange}
                />
            </div>

      </div>
    )
}

export default NewCampaignStep1;