import React, {useState} from "react";
import {Form, Row, Col, Container, Tooltip, OverlayTrigger} from 'react-bootstrap';
import NumericInput from 'react-numeric-input';
import FontPicker from "font-picker-react";
import { GoInfo } from "react-icons/go";
import { FormControl } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import { ToggleButton } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown'
import { FormGroup } from "react-bootstrap";






const EditorSideBar = ({params, handleChange, handleChangeNumericInput, handleChangeFontPicker, handleChangeFileUpload, handleChangeIcon, activeFontFamily, typeOfBanner}) => {

    const renderUploadTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          We recommend uploading a high quality icon in png, jpg, or gif formats. You can find icons for free <a href="https://www.flaticon.com" target="_blank">here</a>.
        </Tooltip>
    );

    const renderHeaderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Make your Header Text short and catchy to grab your site visitor's attention. Share a promotion, shipping info, a review, product details, etc.
        </Tooltip>
    );

    const renderBodyTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Share the details of your message, like how to redeem the promotion, how to qualify for free shipping, the name of the customer who wrote the review, etc.
        </Tooltip>
    );

    const renderFontWeightTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          If you don't see the font weight being applied to your banner, it's likely because your font family doesn't support this weight.
        </Tooltip>
    );

    const renderMarginTopTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Margin top is the number of pixels between your banner and the elements above it on your site. These changes won't look like much here but the spacing will show up when you preview your banner on your site.
        </Tooltip>
    );

    const renderMarginBottomTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Margin bottom is the number of pixels between your banner and the elements below it on your site. These changes won't look like much here but the spacing will show up when you preview your banner on your site.
        </Tooltip>
    );

    const renderBannerWidthTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Can be in px or %
        </Tooltip>
    );

    const renderIconAtrribution = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Icons from <a href="https://www.flaticon.com" target="_blank"> www.flaticon.com</a>
        </Tooltip>
    );

    const iconList = [
        'https://d264kripi6xbpg.cloudfront.net/delivery-truck+(white).png',
        'https://d264kripi6xbpg.cloudfront.net/003-free-delivery.png',
        'https://d264kripi6xbpg.cloudfront.net/free-delivery.png',
        'https://d264kripi6xbpg.cloudfront.net/006-delivery-truck.png',
        'https://d264kripi6xbpg.cloudfront.net/box.png',
        'https://d264kripi6xbpg.cloudfront.net/shipping.png',
        'https://d264kripi6xbpg.cloudfront.net/002-rating-1.png',
        'https://d264kripi6xbpg.cloudfront.net/008-rating-2.png',
        'https://d264kripi6xbpg.cloudfront.net/best-seller.png',
        'https://d264kripi6xbpg.cloudfront.net/good-feedback.png',
        'https://d264kripi6xbpg.cloudfront.net/rating+(1).png',
        'https://d264kripi6xbpg.cloudfront.net/rating.png',
        'https://d264kripi6xbpg.cloudfront.net/004-donation.png',
        'https://d264kripi6xbpg.cloudfront.net/donation.png',
        'https://d264kripi6xbpg.cloudfront.net/010-planet-earth.png',
        'https://d264kripi6xbpg.cloudfront.net/planet-earth.png',
        'https://d264kripi6xbpg.cloudfront.net/011-eco-tag.png',
        'https://d264kripi6xbpg.cloudfront.net/eco-tag.png',
        'https://d264kripi6xbpg.cloudfront.net/012-love.png',
        'https://d264kripi6xbpg.cloudfront.net/love.png',
        'https://d264kripi6xbpg.cloudfront.net/017-strong.png',
        'https://d264kripi6xbpg.cloudfront.net/strong.png',
        'https://d264kripi6xbpg.cloudfront.net/feminine.png',
        'https://d264kripi6xbpg.cloudfront.net/heart.png',
        'https://d264kripi6xbpg.cloudfront.net/helping-hand.png',
        'https://d264kripi6xbpg.cloudfront.net/save-the-planet.png',
        'https://d264kripi6xbpg.cloudfront.net/018-ball-of-wool.png',
        'https://d264kripi6xbpg.cloudfront.net/ball-of-wool.png',
        'https://d264kripi6xbpg.cloudfront.net/019-handmade.png',
        'https://d264kripi6xbpg.cloudfront.net/handmade.png',
        'https://d264kripi6xbpg.cloudfront.net/020-mixing.png',
        'https://d264kripi6xbpg.cloudfront.net/mixing.png',
        'https://d264kripi6xbpg.cloudfront.net/021-bowl.png',
        'https://d264kripi6xbpg.cloudfront.net/bowl.png',
        'https://d264kripi6xbpg.cloudfront.net/028-scissors.png',
        'https://d264kripi6xbpg.cloudfront.net/scissors.png',
        'https://d264kripi6xbpg.cloudfront.net/sale.png',
        'https://d264kripi6xbpg.cloudfront.net/022-price-tag.png',
        'https://d264kripi6xbpg.cloudfront.net/price-tag.png',
        'https://d264kripi6xbpg.cloudfront.net/023-megaphone.png',
        'https://d264kripi6xbpg.cloudfront.net/megaphone.png',
        'https://d264kripi6xbpg.cloudfront.net/024-coupon.png',
        'https://d264kripi6xbpg.cloudfront.net/coupon.png',
        'https://d264kripi6xbpg.cloudfront.net/027-donation-1.png',
        'https://d264kripi6xbpg.cloudfront.net/donation+(1).png',
        'https://d264kripi6xbpg.cloudfront.net/029-promotions.png',
        'https://d264kripi6xbpg.cloudfront.net/promotions.png',
        'https://d264kripi6xbpg.cloudfront.net/valentines-day.png',

    ]

    const [radioValue, setRadioValue] = useState('1');
    
    const [iconPickerShow, setIconPickerShow] = useState(false);

    const handleIconPickerToggle = () => {
        if(iconPickerShow === true){
            setIconPickerShow(false);
        }
        if(iconPickerShow === false){
            setIconPickerShow(true)
        }
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      
          return (
            <div
              ref={ref}
              style={style}
              className={className}
              aria-labelledby={labeledBy}
            >
              <ul className="iconPickerList">
                {React.Children.toArray(children)}
              </ul>
            </div>
          );
        },
      );

    return (
        <Container className="editorSideBar">
        <Form>
            <Row>
            <Col>
            <Form.Group>
                <Form.Label>
                    Header text
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderHeaderTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" name="header_text" defaultValue={params['header_text']} onChange={handleChange}/>
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
            <Form.Group>
                <Form.Label>
                    Body text
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderBodyTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" name="body_text" defaultValue={params['body_text']} onChange={handleChange} />
            </Form.Group>
            </Col>
            </Row>            
            <Row>
            <Col md={12} lg={6}>

            <Form.Group>
                <Form.Label>Header font size</Form.Label>
                <Form.Control as="select" name="header_font_size" defaultValue={params['header_font_size']} onChange={handleChange} custom>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>16</option>
                </Form.Control>
            </Form.Group>
            </Col>
            <Col md={12} lg={6}>

            <Form.Group>
                <Form.Label>Body font size</Form.Label>
                <Form.Control as="select" name="body_font_size" defaultValue={params['body_font_size']} onChange={handleChange}  custom>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>16</option>
                </Form.Control>
            </Form.Group>
            </Col>
            </Row>            
            <Row>
            <Col md={12} lg={6}>

            <Form.Group>
                    <Form.Label>
                        Header font weight
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFontWeightTooltip}
                        >
                        <GoInfo style={{margin:'5px'}}/>
                        </OverlayTrigger>
                    </Form.Label>
                    
                <Form.Control as="select" name="header_font_weight" defaultValue={params['header_font_weight']} onChange={handleChange} custom>
                    <option>lighter</option>
                    <option>normal</option>
                    <option>bold</option>
                    <option>bolder</option>
                </Form.Control>
            </Form.Group>
            </Col>
            <Col md={12} lg={6}>

            <Form.Group>
                    <Form.Label>
                        Body font weight
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFontWeightTooltip}
                        >
                        <GoInfo style={{margin:'5px'}}/>
                        </OverlayTrigger>
                    </Form.Label>
                <Form.Control as="select" name="body_font_weight" defaultValue={params['body_font_weight']} onChange={handleChange} custom>
                    <option>lighter</option>
                    <option>normal</option>
                    <option>bold</option>
                    <option>bolder</option>
                </Form.Control>
            </Form.Group>
            </Col>
            </Row> 
            {(typeOfBanner() === 'medium' || typeOfBanner() === 'large') &&         
            <Row>
            <Col md={12} lg={6}>

            <Form.Group>
                <Form.Label>
                    Choose your icon
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 150, hide: 1900 }}
                    overlay={renderUploadTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                    </OverlayTrigger>
                </Form.Label>

                <ToggleButton
                    type="radio"
                    value="1"
                    checked={radioValue === '1'}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    className="iconRadioButtons"
                >
                    {" Upload from your computer"}
                </ToggleButton>

                <ToggleButton
                    type="radio"
                    value="2"
                    checked={radioValue === '2'}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    className="iconRadioButtons"
                >
                    {" Choose from our library"}
                </ToggleButton>
            </Form.Group>
            </Col>
            <Col md={12} lg={6}>
            <Form.Group>
                <Form.Label>Icon width</Form.Label>
                <NumericInput className="form-control" min={0} max={100} name="icon_width" defaultValue={params['icon_width']} onChange={val => handleChangeNumericInput("icon_width", val)}/>
            </Form.Group>
            </Col>
            </Row> 
            }
            <Row>
            {(typeOfBanner() === 'medium' || typeOfBanner() === 'large') &&         

            <Col>
            <Form.Group>
                <Form.Label>Your Icon</Form.Label>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderIconAtrribution}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                </OverlayTrigger>
                {radioValue === '1' &&
                    <Form.File id="custom-file" label="Icon" data-browse="Choose File" onChange={handleChangeFileUpload} custom/>

                }
                {radioValue === '2' &&
                    <Dropdown>
                        <Dropdown.Toggle className="iconPickerButton">
                            Library
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu} className="iconPicker">
                            {iconList.map(icon_url => (
                                <Dropdown.Item onClick={() => handleChangeIcon(icon_url)} className="iconMenuItem">
                                    <div className="iconPickerHolder">
                                        <img className="iconPickerItem" src={icon_url}/>
                                    </div>
                                </Dropdown.Item>
                            ))}
                            
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </Form.Group>
            </Col>
            }
            <Col>
            <FormGroup>
                <Form.Label>Font family</Form.Label>
                <br/>
                <FontPicker
                    apiKey="AIzaSyAS9CKH_GsWDlauos9ijHgIJnhBl3N72to"
                    activeFontFamily={params['font_family']}
                    onChange={handleChangeFontPicker}
                    limit="250"
                />
            </FormGroup>
            </Col>
            </Row>           
            <Row>
            <Col md={12} lg={6}>

            <Form.Group >
                <Form.Label>Background color</Form.Label>
                <Form.Control type="color" name="background_color_hex_code" defaultValue={params['background_color_hex_code']} onChange={handleChange}/>
            </Form.Group>
            </Col>
            <Col md={12} lg={6}>

            <Form.Group>
                <Form.Label>Font color</Form.Label>
                <Form.Control type="color" name="font_color_hex_code" defaultValue={params['font_color_hex_code']} onChange={handleChange}/>
            </Form.Group>
            </Col>
            </Row>            
            <Row>
            <Col md={12} lg={6}>

            <Form.Group>
                <Form.Label>
                    Margin top
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderMarginTopTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                </OverlayTrigger>
                </Form.Label>
                <NumericInput  className="form-control" defaultValue={params['banner_margin_top']} onChange={val => handleChangeNumericInput("banner_margin_top", val)}/>
            </Form.Group>
            </Col>
            <Col md={12} lg={6}>
            <Form.Group >
                <Form.Label>
                    Margin bottom
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderMarginBottomTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                    </OverlayTrigger>
                </Form.Label>
                <NumericInput  className="form-control" defaultValue={params['banner_margin_bottom']} onChange={val => handleChangeNumericInput("banner_margin_bottom", val)}/>
                </Form.Group>
            </Col>
            </Row>  
            <Row>
            <Col md={12} lg={6}>
            <Form.Group>
                <Form.Label>
                    Banner width
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderBannerWidthTooltip}
                    >
                    <GoInfo style={{margin:'5px'}}/>
                    </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" name="banner_width" defaultValue={params['banner_width']} onChange={handleChange}/>
            </Form.Group>
            </Col>
            {(typeOfBanner() === 'medium' || typeOfBanner() === 'small') &&
            <Col md={12} lg={6}>
            <Form.Group >
                <Form.Label>
                    Line height
                </Form.Label>
                <NumericInput  className="form-control" defaultValue={params['line_height']} onChange={val => handleChangeNumericInput("line_height", val)}/>
                </Form.Group>
            </Col>
            }
            </Row>
        </Form>
        </Container>
    )
      

    
}

export default EditorSideBar;