import React, {useContext, useEffect, useState} from 'react'
import { useAuth0 } from "../react-auth0-spa";
import CauseNone from '../components/Cause/CauseNone'
import StartFreeTrial from '../components/Cause/StartFreeTrial'
import CauseList from '../components/Cause/CauseList'
import { CauseContext } from '../contexts/CauseContext';
import Modal from 'react-bootstrap/Modal';


const CauseDashboard = () => {
    const { authToken, getCampaignList, campaignList, orgIsSubscribed, confirmSubscriptionForOrg } = useContext(CauseContext);
    const chargeIdPassedIn = new URLSearchParams(window.location.search).get('charge_id');
    const [chargeId, setChargeId] = useState(chargeIdPassedIn)
    const [viewPopup, setViewPopUp] = useState(false)
    const {org, user} = useAuth0()
    const drift = window.drift;
    const FS = window.FS;

    drift.identify(user.email, {email: user.email});
    // This is an example script - don't forget to change it!
    FS.identify(user.email, {
        email: user.email,
    });

    useEffect(() => {
        if (authToken === undefined) {
            return;
        }
        if (!orgIsSubscribed && chargeId) {
            confirmSubscriptionForOrg(chargeId);
        }

        getCampaignList();
  
    }, [authToken, orgIsSubscribed]);

    useEffect(() => {
        let seenPopUp = localStorage["seenPopUp"];
        if(seenPopUp === "true") {
            console.log("In true case")
            setViewPopUp(false)
            //do not view Popup
        } else {
            //this is the first time
            setViewPopUp(true)
        } 
    }, []);


    const handleReviewClose = () => {
        localStorage["seenPopUp"] = true
        setViewPopUp(false)
    }

    const handleLeaveReview = () => {
        localStorage["seenPopUp"] = true
        setViewPopUp(false)
        window.open("https://apps.shopify.com/because/reviews", "_blank");
    }

    function getDashboard() {
        
        if (orgIsSubscribed) {
            return (<div className="full-height">
            {campaignList && campaignList.length > 0 && <CauseList list={campaignList}/> }
            {campaignList && campaignList.length === 0 && <CauseNone/> }
            {
                <Modal show={campaignList && viewPopup && (campaignList.length > 2 )} onHide={handleReviewClose} dialogClassName="modalStyling" centered>
                    <div className="modalHeaderGradient"></div>
                    <Modal.Header style={{paddingRight:'30px', paddingLeft:'30px', paddingTop:'15px'}} className="modalHeaderStyling" closeButton>
                        <Modal.Title className="modalTitleStyling" >
                        <span style={{fontWeight:'bold'}}> Give Because a review? </span> 
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBodyStyling" style={{padding:'30px'}}>
                        As a new female founded startup, the number one thing that helps us grow is your feedback!
                        <br/>
                        <br/>
                        Mind popping in and writing us a review in Shopify?
                    </Modal.Body>
                    <Modal.Footer className="modalFooterStyling" style={{paddingRight:'30px', paddingLeft:'30px', paddingBottom:'15px'}}>
                        <button type="button" className="confirmationModalDleteButton" style={{width:'30%'}} onClick={handleLeaveReview}>
                            Write a review              
                        </button>
                        <button type="button" onClick={handleReviewClose} className="confirmationModalCancelButton">
                            Not Now
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>)
        }
        if (typeof orgIsSubscribed != 'undefined' && !orgIsSubscribed) {
            return (<div className="full-height"><StartFreeTrial/></div>)
        }
        else return <div className="full-height"></div>
    }

    return getDashboard();
};

export default CauseDashboard