import React from 'react'
import ReactPlayer from 'react-player'
import {BsBoxArrowUpRight} from "react-icons/bs";
import bestPractices from '../images/Best.jpg'
import helpImage from '../images/help.jpg'

const Resources = () => {
    return (
        <div class="resourceList">
            <div style={{width:'60%', margin:'auto'}}>
                <h2 style={{marginTop: "50px", marginBottom:'15px', fontSize:"24px", fontWeight:"bold"}}>Resources</h2>
            </div>
            <div className="resourceBox">
                <div style={{width:'40%', height:'98%', padding:'10px'}}>
                    <ReactPlayer
                        url='https://youtu.be/m-7gt2k-vNo'
                        width='100%'
                        height='100%'
                    />
                </div>
                <div className="textInResourceBoxContainer">
                    <p className="resourceBoxHeader">Need help getting setup with Because?</p>
                    <p>Check out this video for everything you need to go live with your first Because banner. We cover creating your first campaign, selecting products to display your banner on, how to use the editor, and how to preview your banner live on your site.</p>
                </div>
            </div>
            <div className="resourceBox"> 
                <div style={{width:'40%', height:'98%', padding:'10px'}}>
                <img style={{width:'100%', height:'100%'}} src={bestPractices} />
                </div>
                <div className="textInResourceBoxContainer">
                    <p className="resourceBoxHeader">Seeking inspiration? See how other Shopify merchants are using Because.</p>
                    <p className="resourceBoxText">We put together a library of some of our favorite Because banners that Shopify merchants have created and launched in their stores. We also included some important dos and donts as well as how to think about creating your first banner.</p>
                    <div >
                        <a className="resourceBoxExternalLink" target="_blank" href="https://lp.becauseintelligence.com/because_banner_setupguide">
                            <p style={{marginRight:'5px'}}>Go to inspiration library</p>
                            <BsBoxArrowUpRight />
                        </a>
                    </div>
                </div>
            
            </div>
            <div className="resourceBox"> 
                <div style={{width:'40%', height:'98%', padding:'10px'}}>
                <img style={{width:'100%', height:'100%'}} src={helpImage} />
                </div>
                <div className="textInResourceBoxContainer">
                    <p className="resourceBoxHeader">Need more help? Visit our help center!</p>
                    <p className="resourceBoxText">Our help center is an ever-evolving library of tips on how to best use Because and a collection of FAQs. Have a suggestion on a help doc we should add? Just chat with us below or email app-support@becauseintelligence.com.</p>
                    <div >
                        <a className="resourceBoxExternalLink" target="_blank" href="https://www.notion.so/Help-Center-e228b13d95724a078d93670d1a5c9d04">
                            <p style={{marginRight:'5px'}}>Go to help center</p>
                            <BsBoxArrowUpRight />
                        </a>
                    </div>
                </div>
            
            </div>
        </div>
    )
};

export default Resources