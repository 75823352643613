import React from "react";
import Campaign from "./CauseListItem"
import { Link } from 'react-router-dom'


const CauseList = (list) => {

const causes = list.list || []
if(causes.length === 0){
  return(
      <div className="empty-list">
          <p>No results found...</p>
      </div>
    )
}

return (
    <section>
      <div className="section-title" style={{marginTop:'10px', marginLeft:'100px', display:'flex', alignItems:'center'}}>
        <h2 style={{marginTop: "50px", fontSize:"24px", fontWeight:"bold"}}>Campaigns</h2>
    <div className="new-campaign-button">
        <Link to='/new_cause'>
          <button type="button" style={{backgroundColor:'#FF0097', borderColor:'#FF0097', fontWeight:'bold', marginTop:'50px'}} className="btn btn-info">Create Campaign</button>
         </Link>
        </div>
        
      </div>
      <div className="cause-list">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 card-deck">
      { causes.map((item, index) => {
          return <Campaign cause={item} key={index} />
        })
      }
      </div>
      </div>
       
    </section>
)

};

export default CauseList;