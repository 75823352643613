import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import history from "../utils/history";

const SignUp = () => {
    const firstNamePassedIn = new URLSearchParams(window.location.search).get('firstName');
    const lastNamePassedIn = new URLSearchParams(window.location.search).get('lastName');
    const websitePassedIn = new URLSearchParams(window.location.search).get('website_');
    const emailPassedIn = new URLSearchParams(window.location.search).get('email');
    const tokenIdPassedIn = new URLSearchParams(window.location.search).get('token_id');

    const [firstName, setFirstName] = useState(firstNamePassedIn || '')
    const [lastName, setLastName] = useState(lastNamePassedIn || '')
    const [website, setWebsite] = useState(websitePassedIn || '')
    const [email, setEmail] = useState(emailPassedIn || '')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const submitSignUpForm = async (firstName, lastName, website, email, password, tokenId) => {
        setLoading(true)
        const payload = JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            shop: website,
            email: email,
            password: password,
            token_id: tokenId
        })

        fetch('https://api.because-intelligence.com/sign_up', {
            method: 'POST',
            redirect: 'follow',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: payload
        })
        .then(response => {
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            console.error('Error:', error);
        })
        .then(data => {
            history.push('/')
        })
    }

    function handleSubmit(evt) {
        let formIsValid = true;

        if (!email) {
            formIsValid = false;
        }
        if (!firstName) {
            formIsValid = false;
        }
        if (!lastName) {
            formIsValid = false;
        }
        if (!website) {
            formIsValid = false;
        }
        if (!password) {
            formIsValid = false;
        }

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var test = strongRegex.test(password);
        if (!test) {
            formIsValid = false;
            alert('Password must contain the following:\n \u2022 at least 8 characters \n \u2022 contain a lowercase letter \n \u2022 contain an uppercase letter \n \u2022 a number \n \u2022 a special character.')
            return;
        }

        if (formIsValid) {
            submitSignUpForm(firstName, lastName, website, email, password, tokenIdPassedIn)
        } else {
            alert('All fields are required')
        }
    }

    return (
        <>
           {loading ?
            <div>
                <div className="loading">
                    <h1 >Creating your account...</h1>
                    <Spinner animation="border" size="lg" variant="secondary" />
                </div>
            </div>
            :
            <div className="sign-up">
            <div className="sign-up helper-text">
                <h1>We're syncing with Shopify.</h1>
                <h2>The last step is to create your account.</h2>
                Already have an account? <a href="https://app.because-intelligence.com">Log in.</a>
            </div>
            <div className="row justify-content-center">
                <div className="col col-md-5 form"> 
                <Form onSubmit={handleSubmit}>
                    <Form.Group  >
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Last name</Form.Label>
                        <Form.Control type="text" name="lastName" value={lastName}  onChange={(e) => setLastName(e.target.value)}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Company website</Form.Label>
                        <Form.Control type="text" name="website" value={website} readOnly/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Create a password</Form.Label>
                        <Form.Control type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Sign Up</Button>
                </Form>
                </div>
            </div>
            </div>
            }
        </>
    )
}
export default SignUp