import React from "react";

const NewCampaignFooter = ({currentStep, handleGoToNextStep, handleGoBack, getIsNextDisabled}) => {

    const getProgressHighlighting = () => {
        if (currentStep === 1) {
            return (
                <div className="create-campaign-progress">
                    <p className="progress-element-active">1. Name </p>
                    <p className="progress-element">2. Style </p>
                    <p className="progress-element">3. Display Type </p>
                    <p className="progress-element">4. Product Targeting</p>
                    <p className="progress-element">5. Design</p>
                </div>
            )
        }
        if (currentStep === 2) {
            return (
                <div className="create-campaign-progress">
                    <p className="progress-element">1. Name </p>
                    <p className="progress-element-active">2. Style </p>
                    <p className="progress-element">3. Display Type </p>
                    <p className="progress-element">4. Product Targeting</p>
                    <p className="progress-element">5. Design</p>
                </div>
            )
        }
        if (currentStep === 3) {
            return (
                <div className="create-campaign-progress">
                    <p className="progress-element">1. Name </p>
                    <p className="progress-element">2. Style </p>
                    <p className="progress-element-active">3. Display Type </p>
                    <p className="progress-element">4. Product Targeting</p>
                    <p className="progress-element">5. Design</p>
                </div>
            )
        }
        if (currentStep === 4) {
            return (
                <div className="create-campaign-progress">
                    <p className="progress-element">1. Name </p>
                    <p className="progress-element">2. Style </p>
                    <p className="progress-element">3. Display Type </p>
                    <p className="progress-element-active">4. Product Targeting</p>
                    <p className="progress-element">5. Design</p>
                </div>
            )
        }
        if (currentStep === 5) {
            return (
                <div className="create-campaign-progress">
                    <p className="progress-element">1. Name </p>
                    <p className="progress-element">2. Style </p>
                    <p className="progress-element">3. Display Type</p>
                    <p className="progress-element">4. Product Targeting</p>
                    <p className="progress-element-active">5. Design</p>
                </div>
            )
        }
    }
    return (
        <div className="footer">
            {currentStep !== 1 &&
                <div>
                    <button style={{textAlign:'left', height:'auto', margin:'10px'}} onClick={handleGoBack}  className="btn btn-info">Back</button>
                </div>
            }
            {getProgressHighlighting()}
            {currentStep !== 5 &&
                <div>
                    <button style={{textAlign:'right', height:'auto', margin:'10px'}} disabled={getIsNextDisabled()} onClick={handleGoToNextStep} className="btn btn-info">Next</button>
                </div>
            }
      </div>
    )
};

export default NewCampaignFooter;