import React, { useEffect } from "react"
import { Route } from "react-router-dom"
import { useAuth0 } from "../react-auth0-spa"

import AppRoute from './AppRoute.js'

const AuthRoute = ({ component: Component, path, ...rest }) => {
    const { isAuthenticated, loginWithRedirect, user, setVisible } = useAuth0();
    const seeInfluencers = [1, 11, 12]

    useEffect(() => {
        const fn = async () => {
            if (!isAuthenticated) {
                await loginWithRedirect({
                    appState: { targetUrl: path }
                });
            }
        };
        fn();
    }, [isAuthenticated, loginWithRedirect, path]);

    const render = props => {
        if(isAuthenticated === true && user) {
            if(path !== "/influencers"){
                return <AppRoute component={Component} {...props} />
            }else if(path === "/influencers" && setVisible(user, seeInfluencers)){
                return <AppRoute component={Component} {...props} />
            }else{
                return null;
            }
            
        } else {
            return null;
        }
    }

    return <Route path={path} render={render} {...rest} />;
};

export default AuthRoute;