import React from "react";

const AppRoute = ({component: Component, path, ...rest}) => {
    
    return (
        <>
            <Component {...rest} className="full-height"/>
        </>
    )
};

export default AppRoute;