import React, {useContext, useEffect, useState} from 'react'
import {CauseContext} from '../../contexts/CauseContext'
import history from "../../utils/history";
import { Link } from 'react-router-dom';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';



const CauseListItem = ({cause}) => {
  const [campaign, setCampaign] = useState(cause);

  const [modalShow, setModalShow] = useState(false);
  const [cloneShow, setCloneShow] = useState(false);  //NEW ITEMS
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleCloneClose = () => setCloneShow(false); //NEW ITEMS
  const handleCloneShow = () => setCloneShow(true);   //NEW ITEMS

  const context = useContext(CauseContext)
  const { publishCampaign, unpublishCampaign , deleteCampaign, cloneCampaign} = context
  const {
    id,
    name,
    last_published_at,
    updated_at,
    page_type,
    shop_name
  } = campaign;

  useEffect(() => {
    setCampaign(cause);
  }, [cause]);

  const handlePublishCampaign = async () => {
    publishCampaign(id);
  }

  const handleUnpublishCampaign = async () => {
    unpublishCampaign(id);
  }

  const handleClick = () => {
    history.push(`/cause/${id}`)
  }

  const handleDeleteCampaign = () => {
    handleModalClose();
    deleteCampaign(id);
  }

  const handleCloneCampaign = () => {
    handleCloneClose();
    cloneCampaign(id, newCampaignName, checkboxChecked);
  }

  const handleCheckboxChange = (event) =>{
     setCheckboxChecked(event.target.checked);
  }

  const handleTextboxChange = (event) =>{
    setNewCampaignName(event.target.value);
  }
  
  const Checkbox = props => (
    <input type="checkbox" {...props} />
  )

  const Textbox = props => (
    <input type="text" {...props} />
  )


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
    {children}
    <BiDotsHorizontalRounded className="threeDotIcon"> </BiDotsHorizontalRounded>
    </a>
  )); 

  return (

    <div className="col mb-4">
      <div className="card cause-card">
      <div className="card-body" onClick={handleClick}>
        <h5 className="card-title" style={{fontSize:'16px', fontWeight:'bold'}}>{name}</h5>
        <p style={{fontSize:'14px'}}><b>Store name:</b> {shop_name}</p>
        <p style={{fontSize:'14px'}}><b>Location:</b> {page_type === 'PDP' ? 'Product Detail Page' : 'Cart'}</p>
        <p style={{fontSize:'14px'}}><b>Published:</b> {last_published_at ? (new Date(last_published_at)).toString() : "--"}</p>
        <p style={{fontSize:'14px'}}><b>Last Updated:</b> {(new Date(updated_at)).toString() || "--"}</p>
        
      </div>
      
      <div className="card-footer">
        {last_published_at === null && 
          <button type="button" style={{backgroundColor:'#20267A', borderColor:'#20267A', fontWeight:'bold'}} className="btn btn-info" onClick={handlePublishCampaign}>Publish Campaign</button>
        }
        {last_published_at && 
           <button type="button" style={{backgroundColor:'#FFFFFF', borderColor:'#000000', color:'#000000', fontWeight:'bold'}} className="btn btn-secondary" onClick={handleUnpublishCampaign}>Unpublish Campaign</button>
        }

        <Dropdown className="threeDotMenu">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/*
            <Dropdown.Item as="button" onClick={handleCloneCampaign}> Clone Campaign</Dropdown.Item>
            <Dropdown.Divider> </Dropdown.Divider>
            */}
            <Dropdown.Item as="button" onClick={handleModalShow}> Delete Campaign</Dropdown.Item>
            <Dropdown.Item as="button" onClick={handleCloneShow}> Clone Campaign</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Modal show={modalShow} onHide={handleModalClose} dialogClassName="modalStyling" centered>
          <div className="modalHeaderGradient"></div>
          <Modal.Header className="modalHeaderStyling" closeButton>
            <Modal.Title className="modalTitleStyling">
              <span> Are you sure you want to delete </span> 
              <span style={{fontWeight: 'bold'}} > {name}? </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalBodyStyling">
            If you delete a campaign it will not show up in this dashboard and will be unpublished from your website. 
            You will still be able to see its historical performance in the Reports tab.
          </Modal.Body>
          <Modal.Footer className="modalFooterStyling">
            <button type="button" onClick={handleDeleteCampaign} className="confirmationModalDleteButton">
              Delete              
            </button>
            <button type="button" onClick={handleModalClose} className="confirmationModalCancelButton">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={cloneShow} onHide={handleCloneClose} dialogClassName="modalStyling" centered>
          <div className="modalHeaderGradient"></div>
          <Modal.Header className="modalHeaderStyling" closeButton>
            <Modal.Title className="modalTitleStyling">
              <span>Make a copy of  </span> 
              <span style={{fontWeight: 'bold'}} > {name}? </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalBodyStyling">
                
              <label style={{width:'90%'}}>
                <span style={{fontSize:'14px'}}>Name: </span>
                  <input
                    type="text"
                    value={newCampaignName}
                    onChange={handleTextboxChange}
                    style={{width: "100%", fontSize:"14px"}}
                  />
              </label>

              {
               <p>{"\n"}</p>
              }
              
              <label>
              <span style={{fontSize:'14px'}}>Copy Products selected: </span>
                <Checkbox
                  style={{transform: "scale(1.4)", marginLeft:'3px'}}
                  checked={checkboxChecked}
                  onChange={handleCheckboxChange}
                />
              </label>
          </Modal.Body>
          <Modal.Footer className="modalFooterStyling">
            <button type="button" onClick={handleCloneCampaign} className="confirmationModalDleteButton">
              Copy              
            </button>
            <button type="button" onClick={handleCloneClose} className="confirmationModalCancelButton">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
    </div>
    
  );

};

export default CauseListItem;