import React from 'react'
import loadingGif from '../images/loading.svg'

export default function Loading() {
    return (
        <div className="row">
        <div className="col-lg-10 loading">
            <h4>Loading...</h4>
            <img src={loadingGif} alt="loading"></img>
        </div>
    </div>
    )
}
