import React, {useContext, useEffect} from 'react'
import {CauseContext} from '../contexts/CauseContext'
import ProductTargeting from "../components/Cause/ProductTargeting"
import CauseDesigner from "../components/Cause/CauseDesigner"


const CauseEditor = (props) => {
  const editType = props.match.params.editType
  const id = props.match.params.id

  const context = useContext(CauseContext)
  const { selectedCause, getCampaign } = context
  
  useEffect(() => {
    getCampaign(id)
    
  }, [])

  return (
    <>
    {editType === "product" &&
      <ProductTargeting selectedCause={selectedCause}/>
    }
    {editType === "designer" && 
      <CauseDesigner selectedCause={selectedCause}/>
    }
    </>
  );
};

export default CauseEditor;