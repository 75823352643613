import React, { useEffect, useState, useContext} from 'react'
import { useAuth0 } from "../react-auth0-spa";
import history from "../utils/history";
import mockPic from '../images/shopify.svg'
import moment from 'moment';
import auth0 from 'auth0-js';
import { CauseContext } from '../contexts/CauseContext';
import Cookies from 'universal-cookie';
import config from "../auth_config.json";


const getShopIsSubscribed = async (shop) => {
    const isSubscribed = await fetch(`https://api.because-intelligence.com/shop_is_subscribed?shop=${shop}`, {
        headers:{
            'Accept': 'application/json'
        }
    })
    .then(response => response.json())
    .catch( e => {
       console.log(e)
    })
    .then(data => {
        return data["subscribed"] === true
    });
    return isSubscribed;
}

const Authenticate = () => {
    const { org } = useAuth0()
    const { setAuthToken } = useContext(CauseContext);
    const [needsToReinitiateFromShopify, setNeedsToReinitiateFromShopify] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [needsToPickPlan, setNeedsToPickPLan] = useState(false);
    const [redirecting, setRedirecting] = useState(false)

    const cookies = new Cookies();

    const doConfirmInstallationForShopRequest = async (code, hmac, shop, timestamp, host) => {
        const payload = JSON.stringify({
            code: code,
            hmac: hmac,
            shop: shop,
            timestamp: timestamp,
            host: host
        })
        fetch('https://api.because-intelligence.com/confirm_installation', {            
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            var now  = Date.now();
            var expirationDate = moment(now).add(5, 'm').toDate();
            cookies.set('because_orgIdForStore', data['org_id'], { path: '/', expires: expirationDate });
            cookies.set('because_shopName', shop, { path: '/', expires: expirationDate });
            kickoffOnlineAuthFlow(shop);
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }

    const doConfirmLoggedInUserRequest = async (code, hmac, shop, timestamp, orgId) => {
        const isSubscribed = await getShopIsSubscribed(shop)
        const payload = JSON.stringify({
            code: code,
            hmac: hmac,
            shop: shop,
            timestamp: timestamp,
            org_id: orgId
        })
        fetch('https://api.because-intelligence.com/confirm_logged_in_user', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            const realOrgIdForUser = data['user']['org_id'];
            const internalIdForUser = data['user']['internal_id'];
            const emailAddressForUser = data['user']['email_address'];
            const becauseOnlineString = realOrgIdForUser + "_" + internalIdForUser + "_" + emailAddressForUser
            const encodedBecauseOnlineString = window.btoa(becauseOnlineString);
            var now  = Date.now();
            var expirationDate = moment(now).add(5, 'm').toDate();
            cookies.set('because_logged_in_user', emailAddressForUser, { path: '/', expires: expirationDate  })
            cookies.set('because_online', encodedBecauseOnlineString, { path: '/', expires: expirationDate  });
            if(!isSubscribed){
                setNeedsToPickPLan(true)
            }
            else {
                authenticate();
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }

    const kickoffApproveCharges = async (orgId, shop, plan) => {
        const isSubscribed = await getShopIsSubscribed(shop);
        if (!isSubscribed) {
            const body = JSON.stringify({org_id: orgId, shop: shop, plan: plan});
            fetch(`https://api.because-intelligence.com/start_free_trial_logged_out`, {
                method: 'POST',
                headers:{
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body:body
            })
            .then(response => {
                return response.json();
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .then(data => {
                window.location.assign(data);
            })
            setNeedsToPickPLan(false)
        } else {
            setNeedsToPickPLan(false)
            authenticate();
        }
    }

    const confirmSubscriptionForOrg = async (id, orgId) => {
        const body = JSON.stringify({subscription_id: id, org_id: orgId});
        fetch(`https://api.because-intelligence.com/confirm_subscription_logged_out`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        })
        .then(response => {
            return response.json() === "OK";
        })
        .catch( e => {
           console.log(e);
           return false;
        })
    }
    
    const confirmInstallationForShop = async (code, hmac, shop, timestamp, host) => {
        const orgInfo = doConfirmInstallationForShopRequest(code, hmac, shop, timestamp, host);
        return orgInfo;
    }

    const kickoffOnlineAuthFlow = async (shop) => {
        fetch(`https://api.because-intelligence.com/shopify_online_permission_url?shop=${shop}`, {
            method: 'GET',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            window.location.assign(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }



    const authenticate = async () => {
        const code = new URLSearchParams(window.location.search).get('code');
        const hmac = new URLSearchParams(window.location.search).get('hmac');
        const shop = new URLSearchParams(window.location.search).get('shop');
        const timestamp = new URLSearchParams(window.location.search).get('timestamp');
        const host = new URLSearchParams(window.location.search).get('host');
        const chargeIdPassedIn = new URLSearchParams(window.location.search).get('charge_id');
        const orgIdPassedIn = new URLSearchParams(window.location.search).get('org_id');

        const emailAddressFromCookie = cookies.get('because_logged_in_user');
        const becauseOnlineFromCookie = cookies.get('because_online');

        if (shop) {
            var now  = Date.now();
            var expirationDate = moment(now).add(5, 'm').toDate();
            cookies.set('because_shopName', shop, { path: '/', expires: expirationDate  });
        }

        if (orgIdPassedIn && shop) {
            var now  = Date.now();
            var expirationDate = moment(now).add(5, 'm').toDate();
            cookies.set('because_orgIdForStore', orgIdPassedIn, { path: '/', expires: expirationDate  });
            cookies.set('because_shopName', shop, { path: '/', expires: expirationDate  });
            kickoffOnlineAuthFlow(shop);
        }

        const orgFromCookie = cookies.get('because_orgIdForStore');
        const shopFromCookie = cookies.get('because_shopName');

        if (code && hmac && shop && timestamp && host) {
            if (!orgFromCookie) {
                confirmInstallationForShop(code, hmac, shop, timestamp, host);
            }
            if (orgFromCookie && !emailAddressFromCookie) {
                doConfirmLoggedInUserRequest(code, hmac, shop, timestamp, orgFromCookie);
            } 
        } 
        if (orgFromCookie && shopFromCookie && emailAddressFromCookie && becauseOnlineFromCookie && !needsToPickPlan) {
            let isSubscribed = await getShopIsSubscribed(shopFromCookie);
            if (!isSubscribed  && chargeIdPassedIn) {
                isSubscribed = true;
                await confirmSubscriptionForOrg(chargeIdPassedIn, orgFromCookie);
            }
            
            if (isSubscribed) {
                const decodedBecauseOnline = window.atob(becauseOnlineFromCookie);
                const email = emailAddressFromCookie;
                //delete the cookies
                cookies.remove('because_orgIdForStore', { path: '/' });
                cookies.remove('because_shopName', { path: '/' });
                cookies.remove('because_logged_in_user', { path: '/' });
                cookies.remove('because_online', { path: '/' });
                //autologin
                if (decodedBecauseOnline && email) {
                    login(email, decodedBecauseOnline);
                } else {
                    console.log("something wen wrong because online and email");
                    setSomethingWentWrong(true);
                    return;
                }
            } else {
                if(!redirecting){
                    console.log("something went wrong not subscribed");
                    setSomethingWentWrong(true)
                }
                return;
            }
        }
        if ((!code && !hmac && !shop && !timestamp)
            && (!orgFromCookie && !shopFromCookie && !emailAddressFromCookie && !becauseOnlineFromCookie)
            && !orgIdPassedIn) {
                setNeedsToReinitiateFromShopify(true);
        }
    }

    const login = (email_address, password) => {
        var auth0Client = new auth0.WebAuth({
            domain: config.domain,
            clientID: config.clientId,
            audience: config.audience,
            redirectUri: window.location.origin,
            responseType: 'token id_token',
            scope: "open_id profile email offline_access"
          });

          auth0Client.login({
            realm: 'Username-Password-Authentication',
            username: email_address,
            password: password,
            redirectUri: window.location.origin
          },(err,authResult)=>{
            if(err){
              console.log(err.description);
              return
            }
            if(authResult){
                auth0Client.parseHash({ hash: window.location.hash }, function(err, authResult) {
                    if (err) {
                      return console.log(err);
                    }
                    setAuthToken(authResult.accessToken);
                  
                    auth0Client.client.userInfo(authResult.accessToken, function(err, user) {
                      // Now you have the user's information
                    });
                  });
                window.history.replaceState({}, document.title, window.location.pathname)
            } 
          })
    }

    
    const onBasicClick = () => {
        var tempOrgFromCookie = cookies.get('because_orgIdForStore');
        var tempShopFromCookie = cookies.get('because_shopName');
        setRedirecting(true)
        kickoffApproveCharges(tempOrgFromCookie, tempShopFromCookie, "BASIC")
    }

    const onProClick = () => {
        var tempOrgFromCookie = cookies.get('because_orgIdForStore');
        var tempShopFromCookie = cookies.get('because_shopName');
        setRedirecting(true)
        kickoffApproveCharges(tempOrgFromCookie, tempShopFromCookie, "PRO")
    }
    
    useEffect(() => {
        if (org) {
            //if theyre already logged in then just redirect
           history.push(`/`);
       } else {
            authenticate();
       }
    })

    if(needsToPickPlan){
        return( 
            <div className="planContainer">
                <h3 className="campaign-input-label-step-2">Get started on a <br/> 15 Day Free Trial with Because</h3>
                <p>Choose the best plan for your store to start your 15-day free trial.</p>
                <div className="planWrapper">
                    <div id="basic" className="planItemContainer">
                        <div id="basic" className="planItem">
                            <p id="basic" style={{fontWeight:'bold', textAlign:'center', paddingBottom:'3%', paddingTop:'3%'}}>BASIC</p>
                            <h1 style={{fontWeight:'bold', textAlign:'center', paddingBottom:'2%'}}>$15/mo</h1>
                            <p id="basic" style={{fontWeight:'bold', textAlign:'center', paddingBottom:'2%'}}>For Stores With {"<"}50 Products</p>
                            <p id="basic" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Unlimited Add to Cart Banners</p>
                            <p id="basic" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Select Products Manually</p>
                            <p id="basic" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Icon Library and No Code Designer</p>
                            <p id="basic" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Works on All Themes</p>
                            <button onClick={onBasicClick} className="btn btn-info" style={{color:'white', backgroundColor:'#FF0097'}}> Select Plan</button>
                        </div>
                    </div>
                    <div id="pro" className="planItemContainer">
                        <div id="pro" className="planItem">
                            <p id="pro" style={{fontWeight:'bold', textAlign:'center', paddingBottom:'3%', paddingTop:'3%'}}>PRO</p>
                            <h1 style={{fontWeight:'bold', textAlign:'center', paddingBottom:'2%'}}>$30/mo</h1>
                            <p id="pro" style={{fontWeight:'bold', textAlign:'center', paddingBottom:'2%'}}>For Stores With 50+ Products</p>
                            <p id="pro" style={{textAlign:'center', color:'gray', paddingBottom:'2%'}}>Everything in Basic, plus:</p>
                            <p id="pro" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Target Products by Tag(s)</p>
                            <p id="pro" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Automation Controls for Scale</p>
                            <p id="pro" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Combine and Automate Shipping, Reviews, Promos and More</p>
                            <p id="pro" style={{textAlign:'center', color:'darkgray', paddingBottom:'2%'}}>Priority Phone Support</p>
                            <button onClick={onProClick} className="btn btn-info" style={{color:'white', backgroundColor:'#FF0097'}}> Select Plan</button>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    } else {
        return (
            <div>
                {needsToReinitiateFromShopify &&
                <a href="https://apps.shopify.com/because?auth=1">
                    <div className="reloginThroughShopifyContainer">
                    <div className="reloginThroughShopifyInner">
                        <div className="reloginImage">
                            <img src={mockPic} alt="cause pic"/>
                        </div>
                        <div className="reloginTextContainer">
                            <p className="reloginText">Sign In with Shopify</p>
                        </div>
                    </div>
                </div>
    
                </a>
                }
                {somethingWentWrong &&
                    <div className="authenticateErrorContainer">
                        <h3>Uh oh! There was a problem logging you in <span role="img" aria-label="sad">😰</span></h3>
                        <p className="authenticateErrorText"> Please chat with us below so we can help</p>
                    </div>
                }
                {!needsToReinitiateFromShopify && !somethingWentWrong && !redirecting &&
                    <div className="authenticateErrorContainer">
                        <h3>Please wait... Logging in</h3>
                    </div>
                }
                {redirecting &&
                    <div className="authenticateErrorContainer">
                        <h3>Please wait... Redirecting</h3>
                    </div>
                }
            </div>
        );
    }
    
}

export default Authenticate;