import React from 'react'


const Logout = () => {
    return (
        <div>
            <p>Please re-login through Shopify</p>
            <button>Login</button>
        </div>
    )
}


export default Logout;